import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Link, Outlet, useNavigate, useSearchParams} from "react-router-dom";
import '../stylesheets/NavBar.css';
import '../stylesheets/NavBar.mobile.css';
import CreamLogo from '../icons/Dupe_Logo_Cream.png';
import NotificationIcon from "../icons/DupeIcons-Notifications.png";
import defaultProfile from "../icons/default-profile-icon.png";
import hamburgerIcon from "../icons/hamburger-icon.png";
import downArrowIcon from "../icons/DupeIcons-DownArrow.png"
import {getNotifications, readNotifications} from "../services/NotificationService";
import messageSelected from "../icons/DupeIcons-23-Messaging.png"
import {signOut} from "aws-amplify/auth";

const NavBar = () => {
    const [user, setUser] = React.useState("");
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const [showProfileDropdown, setShowProfileDropdown] = React.useState(false);
    const [showNotificationDropdown, setShowNotificationDropdown] = React.useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const ref = useRef(null);
    const [style, setStyle] = React.useState({});
    const [notifications, setNotifications] = React.useState([]);
    const [unreadNotifications, setUnreadNotifications] = React.useState(0);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            navigate('/results?search=' + event.target.value);
        }
    }

    const updateSearch = (event) => {
        setSearchInput(event.target.value)
    }

    const Notification = ({notification}) => {
        if (notification.type === "CONTENT_APPROVED") {
            return (
                <div className={"notification-div"}>
                    <img className={"notification-content-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + notification.img_preview_id} />
                    <div className={"d-inline-block"}>
                        <p className={"notification-text"}>Your post was approved!</p>
                        <Link to={"/profile?content="+notification.actor_id}>
                            <p className={"notification-view-now-text"}>View now</p>
                            <p className={"elapsed-time-p"}>&nbsp;{notification.elapsed_time}</p>
                        </Link>
                    </div>
                </div>
            )
        } else if (notification.type === "CONTENT_DENIED") {
            return (
                <div className={"notification-div"}>
                    <img className={"notification-content-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + notification.img_preview_id} />
                    <p className={"inline-notification-text"}>Your photo was denied. Take a look at our Style Guide to see what we're looking for <p className={"elapsed-time-p"}>&nbsp;{notification.elapsed_time}</p></p>
                </div>
            )
        } else if (notification.type === "USER_FOLLOWED") {
            return (
                <div className={"notification-div"}>
                    {notification.actor_profile_image_exists && (
                        <img className={"notification-user-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + notification.actor_id} />
                    )}
                    {!notification.actor_profile_image_exists && (
                        <img className={"notification-user-img"} src={defaultProfile} />
                    )}
                    <div className={"d-inline-block"}>
                        <Link className={"d-inline-block"} to={"/profile/"+notification.follower_name}>
                            <p className={"d-inline-block notification-text"}>{notification.follower_name}</p>
                        </Link>
                        <p className={"d-inline-block notification-text-no-margin"}>&nbsp;followed you</p>
                        <p className={"elapsed-time-p"}>&nbsp;{notification.elapsed_time}</p>
                    </div>
                </div>
            )
        } else if (notification.type === "CAMPAIGN_INVITE") {
            return (
                <div className={"notification-div"}>
                    <img className={"notification-content-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + notification.actor_id} />
                    <div className={"campaign-inline-div"}>
                        <p className={"notification-text"}>You have been invited to join a campaign!</p>
                        <Link to={"/creator-signup"}>
                            <p className={"notification-view-now-text"}>View now</p>
                            <p className={"elapsed-time-p"}>&nbsp;{notification.elapsed_time}</p>
                        </Link>
                    </div>
                </div>
            )
        } else if (notification.type === "UNREAD_MESSAGE") {
            return (
                <div className={"notification-div"}>
                    <img className={"notification-content-img"} src={messageSelected} />
                    <div className={"campaign-inline-div"}>
                        <p className={"notification-text"}>You have unread messages!</p>
                        <Link to={"/creator-signup"}>
                            <p className={"notification-view-now-text"}>View now</p>
                            <p className={"elapsed-time-p"}>&nbsp;{notification.elapsed_time}</p>
                        </Link>
                    </div>
                </div>
            )
        }
    }

    function openNotificationDropdown() {
        setShowNotificationDropdown(true);
        if (unreadNotifications > 0) {
            readNotifications()
                .then(resp => {
                    if (resp.status == 200) {
                        setUnreadNotifications(0);
                    }
                })
        }
    }

    function logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        signOut();
        navigate('/');
    }

    function closeProfileModal() {
        setShowProfileDropdown(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function openSidebar() {
        setSidebarOpen(true);
    }

    function closeSidebar() {
        setSidebarOpen(false);
    }

    function displayProfileDropdown(event) {
        setStyle({
            overlay: {
                backgroundColor: "none",
            }
        })
        setShowProfileDropdown(!showProfileDropdown);
    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        const token = localStorage.getItem("token");
        if (loggedInUser && token) {
            const foundUser = JSON.parse(loggedInUser);
            setUser(foundUser);
            getNotifications()
                .then(resp => {
                    if (resp.status == 200 && resp.data) {
                        setNotifications(resp.data);
                        setUnreadNotifications(resp.data.filter(n => n.read == false).length);
                    }
                })
                .catch(err => {
                    if (err.response.status == 401) {
                        localStorage.removeItem('user');
                        localStorage.removeItem('token');
                        navigate('/home');
                    }
                })
        }
        const search = searchParams.get("search")
        if (search) {
            setSearchInput(search);
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closeSidebar();
                closeProfileModal();
                setShowNotificationDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function LandingBanner() {
        return (
            <Fragment>
                <div className="landing-banner-div standard-font-light">
                    <div>Newsletter coming soon! Click <a className={"white-link"} href={"https://www.blog.dupephotos.com/contact#signup"} target={"_blank"}>here</a> to sign up</div>
                </div>
            </Fragment>
        )
    }

    const ProfileIcon = () => {
        return (
            <div className="pointer" onClick={displayProfileDropdown}>
                {user.profile_image_exists && (
                    <img className="thumbnail-image"
                         src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + user.id}
                         alt="user pic"
                    />
                )}
                {!user.profile_image_exists && (
                    <img className="thumbnail-image"
                         src={defaultProfile}
                         alt="user pic"
                    />
                )}
                <img className="down-arrow"
                     src={downArrowIcon}
                     alt="down arrow"
                />
                {showProfileDropdown && (
                    <div className={"profile-dropdown"} ref={ref}>
                        <Link to="/profile" reloadDocument>
                            <div className={"dropdown-main-div pointer"}>
                                {user.profile_image_exists && (
                                    <img className="profile-dropdown-image"
                                         src={"https://d3p3fw3rutb1if.cloudfront.net/users/" + user.id}
                                         alt="user pic"
                                    />
                                )}
                                {!user.profile_image_exists && (
                                    <img className="profile-dropdown-image"
                                         src={defaultProfile}
                                         alt="user pic"
                                    />
                                )}
                                <div className={"dropdown-info"}>
                                    <h5 className={"font-bold"}>{user.first_name} {user.last_name}</h5>
                                    <p>{user.email}</p>
                                </div>
                            </div>
                        </Link>
                        <div className={"dropdown-second-div"}>
                            <Link to="/profile" style={{ textDecoration: 'none' }} reloadDocument>
                                <p style={{ color: 'black' }}>Profile</p>
                            </Link>
                            <Link to="/creator-signup" style={{ textDecoration: 'none' }}>
                                <p style={{ color: 'black' }}>Work with Brands</p>
                            </Link>
                            <Link to="/aesthetics/trending" style={{ textDecoration: 'none' }}>
                                <p style={{ color: 'black' }}>Trending Aesthetics</p>
                            </Link>
                        </div>
                        <div className={"dropdown-third-div"}>
                            <Link to="/about-us" style={{ textDecoration: 'none' }} reloadDocument>
                                <p>About Us</p>
                            </Link>
                            <Link to="https://www.blog.dupephotos.com/style-guide" style={{ textDecoration: 'none' }} reloadDocument>
                                <p>Style Guide</p>
                            </Link>
                            <Link to="/license-and-terms" style={{ textDecoration: 'none' }} reloadDocument>
                                <p>Terms</p>
                            </Link>
                            <a href={"https://blog.dupephotos.com/contact"} style={{ textDecoration: 'none', color: 'black' }}>
                                <p>Feedback</p>
                            </a>
                            <p onClick={logout} onMouseDown={logout}>Log Out</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (user) {
        return(
            <div className={"window"}>
                <LandingBanner/>
                <nav className="navbar navbar-light dupe-navbar">
                    <div className="col-md-6">
                        <div className="mb-6 search-bar">
                            <div className="dupe-logo">
                                <a href="/home">
                                    <img src={CreamLogo} />
                                </a>
                            </div>
                            <input type="text" className="form-control" placeholder="whats the vibe?" value={searchInput} onChange={updateSearch} aria-describedby="basic-addon3" onKeyDown={handleKeyDown}/>
                            <div className={"profile mobile-profile"}>
                                <ProfileIcon />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 links">
                        <div className="form">
                            <Link to="/about-us">
                                <p className={"text-link font-bold"}>ABOUT US</p>
                            </Link>
                            <a href="https://blog.dupephotos.com">
                                <p className={"text-link font-bold"}>BLOG</p>
                            </a>
                            <a href="https://www.blog.dupephotos.com/style-guide">
                                <p className={"final-text-link font-bold"}>STYLE GUIDE</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className={"profile"}>
                            <ProfileIcon />
                        </div>
                        <div className={"notification-icon-div"}>
                            <div>
                                <div className={"notification-icon-div"}>
                                    <img className="notification-img pointer"
                                         src={NotificationIcon}
                                         alt="user pic"
                                         onClick={openNotificationDropdown}
                                    />
                                    {unreadNotifications > 0 && (
                                        <p className={"notification-num-p"}>{unreadNotifications}</p>
                                    )}
                                    {showNotificationDropdown && (
                                        <div className={"notification-dropdown"} ref={ref}>
                                            <h5 className={"notification-h font-bold"}>UPDATES</h5>
                                            {notifications.map((notification) => (
                                                <Notification notification={notification}></Notification>
                                            ))}
                                            {notifications.length < 1 && (
                                                <p>No updates right now. Check back later!</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={"upload-btn-div"}>
                            <Link to="/upload">
                                <button className={"upload-btn"}>upload a photo</button>
                            </Link>
                        </div>
                    </div>
                </nav>
                <Outlet/>
            </div>
        );
    } else {
        return(
            <div>
                <LandingBanner/>
                <nav className="navbar navbar-light dupe-navbar white-background">
                    <div className="col-md-6">
                        <div className="mb-6 search-bar white-background">
                            <div className="dupe-logo">
                                <a href="/home">
                                    <img src={CreamLogo} />
                                </a>
                            </div>
                            <input type="text" className="form-control fa-search" placeholder="whats the vibe?" value={searchInput} onChange={updateSearch} aria-describedby="basic-addon3" onKeyDown={handleKeyDown}/>
                            <img className="hamburger pointer mobile-ham"
                                 src={hamburgerIcon}
                                 alt="user pic" onClick={openSidebar}
                            />
                        </div>

                    </div>
                    <div className="col-md-6 second-nav-col">
                        <div className={"profile"}>
                            <Link to="/upload">
                                <button className={"upload-btn"}>upload a photo</button>
                            </Link>
                            <Link to="/login">
                                <button className="login-btn" type="submit">login</button>
                            </Link>
                            <Link to="/signup">
                                <button className="signup-btn" type="submit">sign up</button>
                            </Link>
                            <img className="hamburger pointer"
                                 src={hamburgerIcon}
                                 alt="user pic" onClick={openSidebar}
                            />
                        </div>
                    </div>
                </nav>
                <Outlet/>
                <div className={sidebarOpen?"sidenav":"sidenavClosed"} ref={ref}>
                    <h5 className={"side-x pointer font-bold"} onClick={closeSidebar}>X</h5>
                    <div className={"menu-options"}>
                        <Link to="/about-us" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>WTF IS DUPE</h3>
                        </Link>
                        <Link to="/login" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>LOG IN</h3>
                        </Link>
                        <Link to="/signup" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>SIGN UP</h3>
                        </Link>
                        <Link to="/license-and-terms" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>TERMS</h3>
                        </Link>
                        <a href={"https://blog.dupephotos.com/contact"} style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>FEEDBACK</h3>
                        </a>
                        <Link to="https://www.blog.dupephotos.com/style-guide" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>STYLE GUIDE</h3>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default NavBar
