import React, {Fragment, useEffect, useLayoutEffect, useState} from 'react'
import '../stylesheets/Collection.css';
import Content from "./Content";
import {
    createCollection, createCollectionEntry,
    deleteCollection,
    getCollectionContent, updateCollection
} from "../services/CollectionService";
import greyBtn from "../icons/grey-plus-btn.png";
import Modal from "react-modal";
import backArrow from "../icons/back-arrow.png";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import lockIcon from "../icons/lock-icon.png";
import plusIcon from "../icons/grey-plus-btn.png";
import Upload from "./Upload";


const Collection = ({collections, portfolioFlag, ownProfile}) => {

    const [displayCollection, setDisplayCollection] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState({});
    const [editCollection, setEditCollection] = useState({});
    const [collectionContent, setCollectionContent] = useState({});
    const [collectionName, setCollectionName] = React.useState("");
    const [collectionDescription, setCollectionDescription] = React.useState("");
    const [collectionModal, setCollectionModal] = React.useState(false);
    const [editCollectionModal, setEditCollectionModal] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const [uploadPortfolioModal, setUploadPortfolioModal] = React.useState(false);
    const [collectionPage, setCollectionPage] = React.useState(2);
    const [additionalCollectionImages, setAdditionalCollectionImages] = React.useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const style = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    };

    function closePortfolioUpload() {
        setUploadPortfolioModal(false);
    }

    function openPortfolioUpload() {
        setUploadPortfolioModal(true);
    }

    const addPostToPortfolio = (contentId) => {
        let collectionEntry = {}
        collectionEntry["content_id"] = contentId
        collectionEntry["cover_content"] = false
        createCollectionEntry(selectedCollection.id, collectionEntry)
            .then(resp => {
                closePortfolioUpload();
                openCollection(selectedCollection);
            })
    }

    useLayoutEffect(() => {
        const collectionId = searchParams.get("collection");
        if (collectionId) {
            const paramsCollection = collections.filter(collection => {
                return collection.id === collectionId;
            })
            if (paramsCollection && paramsCollection.length > 0) {
                openCollection(paramsCollection[0]);
            }
        }
    }, [collections])

    if (displayCollection) {
        return(
            <div>
                <div className={"collection-header-div"}>
                    <img className={"pointer"} onClick={closeCollection} src={backArrow} />
                    <h3 className={"font-bold"}>{selectedCollection.name}</h3>
                    {ownProfile && (
                        <button className={"blue-btn"} onClick={openEditCollectionModal}>Edit</button>
                    )}
                    {portfolioFlag && ownProfile && (
                        <button className={"gold-btn"} onClick={openPortfolioUpload}>Upload</button>
                    )}
                    {!portfolioFlag && (
                        <p>{selectedCollection.collection_length} Photos</p>
                    )}
                </div>
                <div className={"collection-description-div"}>
                    {selectedCollection.private && (
                        <div className={"secret-collection-header-div"}>
                            <img className={"lock-header-img"} src={lockIcon} />
                            <p>secret collection</p>
                        </div>
                    )}
                    <p>{selectedCollection.description}</p>
                </div>
                <Content content={collectionContent} ownProfile={ownProfile} portfolioFlag={portfolioFlag} retrieveNextContent={retrieveMoreCollection} additionalContent={additionalCollectionImages}/>
                <Modal
                    isOpen={editCollectionModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeEditCollectionModal}
                    className={"edit-collection-modal"}
                    style={style}
                >
                    <form className={"edit-collection-form"}>
                        <h5 className={"font-bold"}>Edit Collection</h5>
                        <div className="form-group">
                            <input type="text" className="form-control" id="exampleFormControlInput1" value={editCollection.name} onChange={updateCollectionName}
                                   placeholder="Collection Name" />
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" id="exampleFormControlTextarea1" value={editCollection.description} maxlength="250" onChange={updateCollectionDescription} placeholder={"Enter description here..."} rows="3"></textarea>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={editCollection.private} onChange={updateCollectionPrivate} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    Lets keep this between us
                                </label>
                        </div>
                        <div className={"edit-collection-btn-div"}>
                            {confirmDelete &&
                                <Fragment>
                                    <p>Are you sure?</p>
                                    <p className={"wait-jk-p pointer"} onClick={e => {setConfirmDelete(false)}}>Wait, jk</p>
                                    <button className={"delete-collection-btn"} onClick={e => {deleteCollectionClick(e)}}>Delete</button>
                                </Fragment>
                            }
                            {!confirmDelete &&
                                <Fragment>
                                    <button className={"delete-collection-btn"} onClick={confirmDeleteClick}>Delete Collection</button>
                                    <button className={"blue-btn"} onClick={e => {saveCollection(e)}}>Save</button>
                                </Fragment>
                            }
                        </div>
                    </form>
                </Modal>
                <Modal
                    isOpen={uploadPortfolioModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closePortfolioUpload}
                    className={"upload-portfolio-modal"}
                    style={style}
                >
                    <Upload portfolioFlag={true} onUploadFunc={addPostToPortfolio}/>
                </Modal>
            </div>
            )
    } else {
        return (
            <div>
                {ownProfile && (
                    <div className={"new-collection-div"}>
                        {portfolioFlag && (
                            <h5 className={"new-collection-h5 pointer"} onClick={openCollectionModal}>Portfolio</h5>
                        )}
                        {!portfolioFlag && (
                            <h5 className={"new-collection-h5 pointer"} onClick={openCollectionModal}>Collection</h5>
                        )}
                        <img className={"new-collection-img pointer"} src={plusIcon} onClick={openCollectionModal} />
                    </div>
                )}
                <div className={"collection-container-div"}>
                    {collections && collections.map((collection) => (
                        <div className="collection pointer" onClick={() => {openCollection(collection)}}>
                            <div className="large-image">
                                {collection.private && (
                                    <img className={"collection-lock-img"} src={lockIcon}/>
                                )}
                                {collection.collection_entries &&
                                <img className={"large-collection-preview-img"} alt="not fount"
                                     src={collection.collection_entries[0].portfolio_flag ? "https://d3p3fw3rutb1if.cloudfront.net/private-content/" + collection.collection_entries[0].img_preview_id : "https://d3p3fw3rutb1if.cloudfront.net/photos/" + collection.collection_entries[0].img_preview_id}/>
                                }
                            </div>
                            <div className="small-container">
                                <div className="small-image">
                                    {collection.collection_entries && collection.collection_entries.length > 1 &&
                                    <img className={"large-collection-preview-img"} alt="not fount"
                                         src={collection.collection_entries[1].portfolio_flag ? "https://d3p3fw3rutb1if.cloudfront.net/private-content/" + collection.collection_entries[1].img_preview_id : "https://d3p3fw3rutb1if.cloudfront.net/photos/" + collection.collection_entries[1].img_preview_id}/>
                                    }
                                </div>
                                <div className="small-image">
                                    {collection.collection_entries && collection.collection_entries.length > 2 &&
                                    <img className={"large-collection-preview-img"} alt="not fount"
                                         src={collection.collection_entries[2].portfolio_flag ? "https://d3p3fw3rutb1if.cloudfront.net/private-content/" + collection.collection_entries[2].img_preview_id : "https://d3p3fw3rutb1if.cloudfront.net/photos/" + collection.collection_entries[2].img_preview_id}/>
                                    }
                                </div>
                            </div>
                            <div>
                                <h3 className={"collection-name-h3 font-bold"}>{collection.name}</h3>
                            </div>
                        </div>
                    ))}
                </div>
                {ownProfile && (collections == null || collections.length < 1) && (
                    <Fragment>
                        <div className="collection pointer" onClick={openCollectionModal}>
                            <div className="large-image">
                                <img className="empty-collection-plus" alt="" src={greyBtn} />
                            </div>
                            <div className="small-container">
                                <div className="small-image">

                                </div>
                                <div className="small-image">

                                </div>
                            </div>
                            <div>
                                {portfolioFlag && (
                                    <h3 className={"collection-name-h3 font-bold"}>Portfolio</h3>
                                )}
                                {!portfolioFlag && (
                                    <h3 className={"collection-name-h3 font-bold"}>Collection</h3>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
                {(collections == null || collections.length < 1) && !ownProfile && (
                    <div className={"empty-collection-div"}>
                        {portfolioFlag && (
                            <h3 className={"font-bold"}>Creator doesn't have a portfolio yet</h3>
                        )}
                        {!portfolioFlag && (
                            <h3 className={"font-bold"}>Creator doesn't have any collections yet</h3>
                        )}
                    </div>
                )}
                <Modal
                    isOpen={collectionModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeCollectionModal}
                    className={"create-collection-modal"}
                    style={style}
                >
                    <div>
                        <div className={"new-collection-header"}>
                            {portfolioFlag && (
                                <h5 className={"font-bold"}>Create Portfolio</h5>
                            )}
                            {!portfolioFlag && (
                                <h5 className={"font-bold"}>Create Collection</h5>
                            )}
                        </div>
                        <div className={"collection-input"}>
                            <input id="firstName" value={collectionName} placeholder={"Name"} onChange={(e) => setCollectionName(e.target.value)} />
                            <textarea id="firstName" value={collectionDescription} placeholder={"Description"} onChange={(e) => setCollectionDescription(e.target.value)} />
                        </div>
                        <div className={"create-collection-footer"}>
                            <button className={"blue-btn create-button"} onClick={createNewCollection}>Create</button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }


    function deleteCollectionClick(event) {
        event.preventDefault();
        deleteCollection(selectedCollection.id)
            .then( resp => {
                window.location.reload();
                }
            )
    }

    function confirmDeleteClick(event) {
        event.preventDefault();
        setConfirmDelete(true);
    }

    function updateCollectionName(event) {
        setEditCollection({
            ...editCollection, // Copy the old fields
            name: event.target.value // But override this one
        })
    }

    function updateCollectionDescription(event) {
        setEditCollection({
            ...editCollection, // Copy the old fields
            description: event.target.value // But override this one
        })
    }

    function updateCollectionPrivate(event) {
        setEditCollection({
            ...editCollection, // Copy the old fields
            private: event.target.checked // But override this one
        })
    }

    function saveCollection(event) {
        event.preventDefault();
        updateCollection(editCollection)
            .then(resp => {
                setEditCollectionModal(false);
                navigate(0);
            })
    }

    function openCollection(collection) {
        getCollectionContent(collection.id, null)
            .then((response) => {
                if (response.status == 200) {
                    searchParams.delete("collection");
                    searchParams.append("collection", collection.id);
                    setSearchParams(searchParams);
                    setCollectionContent(response.data);
                    setDisplayCollection(true);
                    setSelectedCollection(collection);
                    setCollectionPage(2);
                }
            })
    }

    function retrieveMoreCollection() {
        getCollectionContent(selectedCollection.id, collectionPage)
            .then((response) => {
                if (response.status == 200 && response.data) {
                    setAdditionalCollectionImages(response.data);
                    setCollectionPage(collectionPage+1);
                }
            });
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeCollection() {
        searchParams.delete("collection");
        setSearchParams(searchParams);
        setDisplayCollection(false);
    }

    function closeCollectionModal() {
        setCollectionModal(false);
    }

    function openCollectionModal() {
        setCollectionModal(true);
    }

    function closeEditCollectionModal() {
        setEditCollectionModal(false);
    }

    function openEditCollectionModal() {
        setEditCollection({...selectedCollection});
        setEditCollectionModal(true);
    }

    function createNewCollection() {
        if (collectionName) {
            const token = localStorage.getItem("token");
            if (token) {
                let collection = {}
                collection["name"] = collectionName;
                collection["description"] = collectionDescription;
                collection["portfolio"] = portfolioFlag;
                createCollection(collection)
                    .then(response => {
                        setCollectionModal(false);
                        window.location.reload();
                    });
            }
        }
    }

}


export default Collection
