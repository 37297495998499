import React, {Fragment, useEffect, useLayoutEffect, useState} from 'react'
import {
    followUser,
    unfollowUser,
    getProfile,
    getUsernameProfile,
    updateUser,
    checkUsername, getUsersFollowings
} from "../services/UserService";
import '../stylesheets/Profile.css';
import '../stylesheets/Profile.mobile.css';
import {
    deleteContent,
    getContentSummary,
    getLikedContent,
    getUsersContent, getUsersVideoContent,
    updateContent
} from "../services/ContentService";
import Content from "./Content";
import Collection from "./Collection";
import {getCollectionPreviews, getPortfolioPreviews} from "../services/CollectionService";
import Modal from "react-modal";
import {useParams} from "react-router";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import greyBtn from "../icons/grey-plus-btn.png";
import instagramImg from "../icons/DupeIcons-Instagram.png";
import tikTokImg from "../icons/DupeIcons-TikTOk.png";
import cameraIcon from "../icons/DupeIcons-Camera.png";
import collectionIcon from "../icons/DupeIcons-Gallery.png";
import videoIcon from "../icons/DupeIcons-Video.png";
import defaultProfile from "../icons/default-profile-icon.png";
import searchIcon from "../icons/DupeIcons-Search.png";
import likedIcon from "../icons/heat-blue-background.png";
import bizAvailableIcon from "../icons/DupeIcons-17-Biz-Available.png"
import bizUnavailableIcon from "../icons/DupeIcons-16-Biz-Unavailable.png"
import {getAesthetics} from "../services/AestheticService";
import {getCreatorProfile} from "../services/CreatorService";
import { sendUserAttributeVerificationCode, confirmUserAttribute, fetchUserAttributes } from 'aws-amplify/auth';


const Profile = () => {
    const [user, setUser] = useState("");
    const [creatorBizAvailable, setCreatorBizAvailable] = useState(false)
    const [userEdit, setUserEdit] = useState("");
    const [activeTab, setActiveTab] = useState("photos");
    const [photoImages, setPhotoImages] = useState([]);
    const [usersVideos, setUsersVideos] = useState([]);
    const [collections, setCollections] = useState(null);
    const [portfolios, setPortfolios] = useState(null);
    const [likedContent, setLikedContent] = useState([]);
    const [additionalLikedContent, setAdditionalLikedContent] = useState([]);
    const [allLikedContent, setAllLikedContent] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [followingModal, setFollowingModal] = useState(false);
    const [followingTab, setFollowingTab] = useState("");
    const [editContentModal, setEditContentModal] = useState(false);
    const [confirmContentDelete, setConfirmContentDelete] = useState(false);
    const [profileImg, setProfileImg] = useState("");
    const [newImageFlag, setNewImageFlag] = useState(false);
    const [ownProfile, setOwnProfile] = useState(false);
    const [emailVerified, setEmailVerified] = useState(true);
    const [confirmedEmail, setConfirmedEmail] = useState(false);
    const [portfolioDisclaimer, setPortfolioDisclaimer] = useState(true);
    const [profilePage, setProfilePage] = useState(1);
    const [videoPage, setVideoPage] = useState(1);
    const [likedPage, setLikedPage] = useState(2);
    const [profileId, setProfileId] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [selectedContent, setSelectedContent] = useState({});
    const [followings, setFollowings] = useState({});
    const [allFollowings, setAllFollowings] = useState({});
    const [followingSearch, setFollowingSearch] = useState("");
    const [aesthetics, setAesthetics] = useState([]);
    const [allAesthetics, setAllAesthetics] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [selectedAesthetics, setSelectedAesthetics] = useState([]);
    const [showAesthetics, setShowAesthetics] = React.useState(false);
    const [showLabels, setShowLabels] = React.useState(false);
    const [aestheticSearch, setAestheticSearch] = useState("");
    const [labels, setLabels] = useState([]);
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const style = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    }


    function closeModal() {
        setEditModal(false);
        setEditContentModal(false);
        setConfirmContentDelete(false);
        setFollowingModal(false);
        setSelectedContent({});
    }

    function openEditModal() {
        setUserEdit({...user})
        setEditModal(true);
    }

    function openFollowingModal() {
        getUsersFollowings(user.username)
            .then(resp => {
                if (resp.status == 200 && resp.data) {
                    setFollowings(resp.data);
                    setAllFollowings(resp.data);
                    setFollowingTab("following");
                    setFollowingModal(true);
                }
            })
    }

    function searchFollowing(event) {
        setFollowingSearch(event.target.value);
        const newfollowing = allFollowings.following.filter(follow => {
            return (follow.username.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1
                || (follow.first_name + " " + follow.last_name).toUpperCase().indexOf(event.target.value.toUpperCase()) > -1);
        })
        const newfollowers = allFollowings.followers.filter(follow => {
            return (follow.username.toUpperCase().indexOf(event.target.value.toUpperCase()) > -1
                || (follow.first_name + " " + follow.last_name).toUpperCase().indexOf(event.target.value.toUpperCase()) > -1);
        })
        setFollowings({
            followers: newfollowers,
            following: newfollowing
        })
    }

    const openContentEditModal = (event, content) => {
        event.stopPropagation();
        getContentSummary(content.id)
            .then(resp => {
                if (resp.status == 200 && resp.data) {
                    setSelectedContent(resp.data);
                    setEditContentModal(true);
                    setSelectedLabels(resp.data.labels.map(labelObj => {
                        return labelObj.label
                    }));
                    setSelectedAesthetics(resp.data.aesthetics);
                    getAesthetics()
                        .then((aesthResp) => {
                            setAllAesthetics(aesthResp.data);
                            setAesthetics(aesthResp.data);
                        })
                }
            })
    }

    function createLabel(event) {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const index = selectedLabels.indexOf(event.target.value)
                if (index == -1) {
                    setSelectedLabels(selectedLabels.concat(event.target.value));
                }
                event.target.value = '';
        }
    }

    function removeLabel(label) {
        const index = selectedLabels.indexOf(label)
        if (index > -1) {
            selectedLabels.splice(index, 1);
            const newArray = [...selectedLabels];
            setSelectedLabels(newArray);
        }
    }

    function openLabelDropdown() {
        setShowLabels(true);
    }

    function hideLabelDropdown() {
        setShowLabels(false);
    }

    function addAesthetic(aesthetic) {
        if (selectedAesthetics.indexOf(aesthetic) == -1) {
            selectedAesthetics.push(aesthetic);
        }
        setShowAesthetics(false);
        setAestheticSearch('');
    }

    function removeAesthetic(aesthetic) {
        const index = selectedAesthetics.indexOf(aesthetic)
        if (index > -1) {
            selectedAesthetics.splice(index, 1);
            const newArray = [...selectedAesthetics];
            setSelectedAesthetics(newArray);
        }
    }

    function openAestheticDropdown() {
        setShowAesthetics(true);
    }

    function hideAestheticDropdown() {
        setShowAesthetics(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    const updateFirstName = (event) => {
        setUserEdit(prevState => ({
            ...prevState,    // keep all other key-value pairs
            first_name: event.target.value       // update the value of specific key
        }))
    }

    const updateLastName = (event) => {
        setUserEdit(prevState => ({
            ...prevState,    // keep all other key-value pairs
            last_name: event.target.value       // update the value of specific key
        }))
    }

    const updateDescription = (event) => {
        setUserEdit(prevState => ({
            ...prevState,    // keep all other key-value pairs
            description: event.target.value       // update the value of specific key
        }))
    }
    const updateUsername = (event) => {
        setUserEdit(prevState => ({
            ...prevState,    // keep all other key-value pairs
            username: event.target.value       // update the value of specific key
        }))
    }
    const updateInstagram = (event) => {
        setUserEdit(prevState => ({
            ...prevState,    // keep all other key-value pairs
            instagram_handle: event.target.value       // update the value of specific key
        }))
    }
    const updateTikTok = (event) => {
        setUserEdit(prevState => ({
            ...prevState,    // keep all other key-value pairs
            tik_tok_handle: event.target.value       // update the value of specific key
        }))
    }
    const updateYoutube = (event) => {
        setUserEdit(prevState => ({
            ...prevState,    // keep all other key-value pairs
            youtube_handle: event.target.value       // update the value of specific key
        }))
    }

    function triggerUsernameCheck() {
        if (userEdit.username.indexOf(' ') >= 0) {
            setUsernameError("Username cannot contain spaces");
        } else {
            checkUsername(userEdit.username)
                .then(resp => {
                    if (resp.status == 200 && resp.data && resp.data.available == true) {
                        setUsernameError(null);
                    } else {
                        setUsernameError("That username is already taken.");
                    }
                });
        }
    }

    const saveUser = (event) => {
        event.preventDefault();
        if (newImageFlag) {
            userEdit["profile_image"] = profileImg["profile_image"];
            userEdit["profile_image_type"] = profileImg["profile_image_type"];
        } else {
            userEdit["profile_image"] = null;
        }
        if (userEdit.username.indexOf(' ') >= 0) {
            setUsernameError("Username cannot contain spaces");
        } else {
            checkUsername(userEdit.username)
                .then(resp => {
                    if (resp.status == 200 && resp.data && resp.data.available == true) {
                        updateUser(userEdit)
                            .then((result) => {
                                getProfile()
                                    .then(newProfile => {
                                        if (newProfile.status == 200 && newProfile.data) {
                                            localStorage.setItem('user', JSON.stringify(newProfile.data))
                                        }
                                        navigate(0);
                                    })
                            });
                    } else {
                        setUsernameError("That username is already taken.");
                    }
                });
        }
    }

    const handleTab = (tab) => {
        setActiveTab(tab);
        setSearchParams({"tab": tab})
    }

    const setPhotos = (userId) => {
        setProfileId(userId);
        getUsersContent(userId, profilePage)
            .then((response) => {
                if (response.status === 200) {
                    setPhotoImages(response.data);
                    setProfilePage(profilePage + 1);
                }
            });
    }

    function loadMoreImages() {
        getUsersContent(profileId, profilePage)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setPhotoImages(prevState => [...prevState, ...response.data]);
                    setProfilePage(profilePage + 1);
                }
            });
    }

    function loadMoreVideos() {
        getUsersVideoContent(profileId, profilePage)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setUsersVideos(prevState => [...prevState, ...response.data]);
                    setVideoPage(videoPage + 1);
                }
            });
    }

    function loadMoreLikedImages() {
        getLikedContent(likedPage)
            .then((response) => {
                if (response.status === 200 && response.data && response.data.length > 0) {
                    //setAdditionalLikedContent(response.data);
                    setLikedPage(likedPage + 1);
                    setLikedContent(prevState => [...prevState, ...response.data]);
                }
            });
    }

    const setUploadImage = (event) => {
        event.preventDefault();
        const reader = new FileReader();

        reader.onload = (event) => {
            //resolve(event.target.result)
            const img_array = event.target.result.split(",", 2)
            const file_info = img_array[0].split(";", 2)
            const file_type = file_info[0].split(":", 2)[1]
            let new_image = {}
            new_image["profile_image"] = img_array[1];
            new_image["profile_image_type"] = file_type;
            setProfileImg(new_image);
            setNewImageFlag(true);
        };

        reader.onerror = (err) => {
            //reject(err);
        };

        reader.readAsDataURL(event.target.files[0]);
    }

    function setProfile(user) {
        setUser(user)
        if (user.profile_image) {
            setProfileImg(user)
        }
        setPhotos(user.id);
        getUsersVideoContent(user.id, 1)
            .then((response) => {
                if (response.status === 200) {
                    setUsersVideos(response.data);
                    setVideoPage(videoPage + 1);
                }
            });
        getCollectionPreviews(user.id)
            .then((response) => {
                if (response.status === 200) {
                    setCollections(response.data)
                }
            });
        getPortfolioPreviews(user.id)
            .then((response) => {
                if (response.status === 200) {
                    setPortfolios(response.data)
                }
            });
    }

    function updateSelectedContent() {
        let req = {};
        req.id = selectedContent.id;
        req.aesthetics = selectedAesthetics;
        req.labels = selectedLabels;
        updateContent(req)
            .then(response => {
                closeModal();
            })
    }

    function deleteSelectedContent() {
        deleteContent(selectedContent.id)
            .then(response => {
                closeModal();
                window.location.reload();
            })
    }

    function removeFollowing(userId) {
        unfollowUser(userId)
            .then(resp => {
                if (resp.status == 200) {
                    const newFollowing = followings.following.map(follow => {
                        if (follow.user_id === userId) {
                            return {...follow, requestor_following: false};
                        }
                        return follow;
                    })
                    const newFollowers = followings.followers.map(follow => {
                        if (follow.user_id === userId) {
                            return {...follow, requestor_following: false};
                        }
                        return follow;
                    })
                    setFollowings({
                        followers: newFollowers,
                        following: newFollowing
                    })
                }
            })
    }

    function addFollowing(userId) {
        followUser(userId)
            .then(resp => {
                if (resp.status == 200) {
                    let userFound = false;
                    let user = {};
                    const newFollowing = followings.following.map(follow => {
                        if (follow.user_id === userId) {
                            userFound = true;
                            return {...follow, requestor_following: true};
                        }
                        return follow;
                    })
                    const newFollowers = followings.followers.map(follow => {
                        if (follow.user_id === userId) {
                            if (!userFound) {
                                follow.requestor_following = true;
                                user = follow;
                            }
                            return {...follow, requestor_following: true};
                        }
                        return follow;
                    })
                    if (!userFound && ownProfile) {
                        newFollowing.push(user);
                    }
                    setFollowings({
                        followers: newFollowers,
                        following: newFollowing
                    })
                }
            })
    }

    function followUserFunc() {
        followUser(user.id)
            .then(resp => {
                if (resp.status == 200) {
                    setUser(prevState => ({
                        ...prevState,    // keep all other key-value pairs
                        following: true       // update the value of specific key
                    }))
                }
            })
    }

    function unfollowUserFunc() {
        unfollowUser(user.id)
            .then(resp => {
                if (resp.status == 200) {
                    setUser(prevState => ({
                        ...prevState,    // keep all other key-value pairs
                        following: false       // update the value of specific key
                    }))
                }
            })
    }

    function likeToggleFunction(imageId) {
        setPhotoImages(photoImages.map(image => {
            if (image.id === imageId) {
                return {...image, liked: !image.liked};
            }
            return image;
        }))
    }

    function resendEmailVerification() {
        console.log("resendEmailVerification");
        sendUserAttributeVerificationCode({userAttributeKey:'email'})
            .then(resp => {
                setEmailVerified(true);
            })
    }

    useEffect(() => {
        const token = localStorage.getItem("token")
        const userString = localStorage.getItem("user");
        const tab = searchParams.get("tab");
        if (tab) {
            setActiveTab(tab);
        }

        //check to see if email is verified
        fetchUserAttributes().then((user) => {
            console.log(user);
            if (user && user.email_verified === "false") {
                console.log("email verified" + emailVerified);
                setEmailVerified(false);
                const verificationCode = searchParams.get("vcode");
                if (verificationCode) {
                    confirmUserAttribute({userAttributeKey:'email', confirmationCode: verificationCode})
                        .then(resp => {
                            setEmailVerified(true);
                            setConfirmedEmail(true);
                        })
                        .catch(err => {
                            //console.log(err);
                        })
                }
            }
        }).catch((err) => console.log(err));

        if (params.username && (userString == null || JSON.parse(userString).username != params.username)) {
            getUsernameProfile(params.username, token)
                .then((response) => {
                    if (response.status === 200) {
                        setProfile(response.data)
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status == 401) {
                        localStorage.removeItem('user');
                        localStorage.removeItem('token');
                        navigate('/login', {replace: true});
                    }
                })
        } else if (token) {
            getProfile()
                .then((response) => {
                    if (response.status === 200) {
                        setOwnProfile(true);
                        setProfile(response.data);
                        localStorage.setItem('user', JSON.stringify(response.data));
                    }
                })
                .catch(err => {
                    if (err.response && err.response.status == 401) {
                        localStorage.removeItem('user');
                        localStorage.removeItem('token');
                        navigate('/login', {replace: true});
                    }
                })
            getLikedContent(1)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setLikedContent(resp.data);
                    }
                });
            getCreatorProfile().then((resp) => {
                if (resp.status === 200 && resp.data) {
                    setCreatorBizAvailable(resp.data.available)
                }
            }).catch(err => {
                console.error(err)
            })
        } else {
            const verificationCode = searchParams.get("vcode");
            if (verificationCode) {
                navigate('/login?vcode=' + verificationCode);
            } else {
                navigate('/login');
            }
        }
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (allAesthetics.length > 0) {
                setAesthetics(allAesthetics.filter(aesthetic => {
                    if (aestheticSearch === "") {
                        //if query is empty
                        return aesthetic;
                    } else if (aesthetic.aesthetic.toLowerCase().includes(aestheticSearch.toLowerCase())) {
                        //returns filtered array
                        return aesthetic;
                    }
                }))
            }
        }, 100)

        return () => clearTimeout(delayDebounceFn)
    }, [aestheticSearch])

    function Photos() {
        if (photoImages && photoImages.length > 0) {
            return (
                <Content profileFlag={ownProfile} editFunc={openContentEditModal} content={photoImages}
                         retrieveNextContent={loadMoreImages} likeFunc={likeToggleFunction}/>
            );
        } else if (ownProfile) {
            return (
                <div>
                    <div className="collection">
                        <div className="large-image pointer" onClick={event => navigate('/upload')}>
                            <img className="empty-collection-plus" alt="" src={greyBtn}/>
                        </div>
                        <div className={"upload-photo-div"}>
                            <h3 className={"font-bold"}>Upload a Photo</h3>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={"centered-text-div"}>
                    <h3 className={"font-bold blue-text"}>this creator has no photos</h3>
                </div>
            )
        }
    }

    function Videos() {
        if (usersVideos && usersVideos.length > 0) {
            return (
                <Content profileFlag={ownProfile} editFunc={openContentEditModal} content={usersVideos}
                         retrieveNextContent={loadMoreVideos} likeFunc={likeToggleFunction}/>
            );
        } else if (ownProfile) {
            return (
                <div>
                    <div className="collection">
                        <div className="large-image pointer" onClick={event => navigate('/upload')}>
                            <img className="empty-collection-plus" alt="" src={greyBtn}/>
                        </div>
                        <div className={"upload-photo-div"}>
                            <h3 className={"font-bold"}>Upload a Video</h3>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={"centered-text-div"}>
                    <h3 className={"font-bold blue-text"}>this creator has no videos</h3>
                </div>
            )
        }
    }

    if (!user || !collections || !portfolios) {
        return (<div></div>);
    }

    return (
        <div>
            {!emailVerified && (
                <div className={"email-verification-banner-div"}>
                    <p>Your email address has not been verified, <u className={"pointer"}
                                                                    onClick={resendEmailVerification}>click here</u> to
                        resend verification email.</p>
                </div>
            )}
            {confirmedEmail && (
                <div className={"email-confirmation-banner-div"}>
                    <p>Your email has been successfully confirmed!</p>
                </div>
            )}
            <div className="user-header">
                <div className="headshot-container">
                    {user.profile_image_exists &&
                        <img src={"https://d3p3fw3rutb1if.cloudfront.net/users/" + user.id}/>
                    }
                    {!user.profile_image_exists &&
                        <img src={defaultProfile}/>
                    }
                </div>
                <div className="user-info">
                    <h1 className={"font-bold name-header first-name-margin"}>{user.first_name}</h1>
                    <div className={"edit-div"}>
                        <h1 className={"font-bold name-header"}>{user.last_name}</h1>
                        {ownProfile &&
                            <button className={"blue-inverse-btn edit-btn"} onClick={openEditModal}>Edit
                                Profile</button>
                        }
                        {!ownProfile && user.following &&
                            <button className={"blue-btn follow-btn-spacing follow-btn"}
                                    onClick={unfollowUserFunc}>Following</button>
                        }
                        {!ownProfile && !user.following &&
                            <button className={"blue-inverse-btn follow-btn-spacing follow-btn"}
                                    onClick={followUserFunc}>Follow</button>
                        }
                        <button className={"blue-inverse-btn"} onClick={openFollowingModal}>Following List</button>
                    </div>
                    <p>{user.description}</p>
                    <div className={"social-div"}>
                        {user.instagram_handle && (
                            <a href={"https://instagram.com/" + user.instagram_handle} target={"_blank"}>
                                <img src={instagramImg}/>
                            </a>
                        )}
                        {user.tik_tok_handle && (
                            <a href={"https://tiktok.com/@" + user.tik_tok_handle} target={"_blank"}>
                                <img src={tikTokImg}/>
                            </a>
                        )}
                    </div>
                    <ProfileAesthetics/>
                    <CreatorBizStatus/>
                </div>
            </div>
            <div className="content-navbar">
                <nav className="navbar navbar-expand-lg navbar-light bg-none">
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className={["nav-item", activeTab === 'photos' ? "active-nav" : ""].join(" ")}>
                                <a className="nav-link" href="#" onClick={() => handleTab("photos")}>
                                    <img className={"profile-icon-img"} src={cameraIcon}/>Photos
                                </a>
                            </li>
                            <li className={["nav-item", activeTab === 'videos' ? "active-nav" : ""].join(" ")}>
                                <a className="nav-link" href="#" onClick={() => handleTab("videos")}>
                                    <img className={"profile-icon-img"} src={videoIcon}/>Videos</a>
                            </li>
                            <li className={["nav-item", activeTab === 'portfolio' ? "active-nav" : ""].join(" ")}>
                                <a className="nav-link" href="#" onClick={() => handleTab("portfolio")}>
                                    <img className={"profile-icon-img"} src={videoIcon}/>Portfolio</a>
                            </li>
                            <li className={["nav-item", activeTab === 'collections' ? "active-nav" : ""].join(" ")}>
                                <a className="nav-link" href="#" onClick={() => handleTab("collections")}>
                                    <img className={"profile-icon-img"} src={collectionIcon}/>Collections
                                </a>
                            </li>
                            {ownProfile && (
                                <li className={["nav-item", activeTab === 'liked' ? "active-nav" : ""].join(" ")}>
                                    <a className="nav-link" href="#" onClick={() => handleTab("liked")}>
                                        <img className={"profile-icon-img"} src={likedIcon}/>Likes
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
            <div>
                {activeTab === 'photos' && (Photos())}
                {activeTab === 'videos' && (Videos())}
                {activeTab === 'portfolio' && (
                    <div>
                        {portfolioDisclaimer && ownProfile && (
                            <div className={"portfolio-internal-disclaimer-div"}>
                                <p className={"portfolio-ack-p pointer"}
                                   onClick={event => setPortfolioDisclaimer(false)}>I Understand</p>
                                <p className={"portfolio-external-disclaimer-p"}>Your Portfolio content is not royalty
                                    free. Users cannot download or reuse this content in any way without your
                                    permission.</p>
                                <p className={"portfolio-external-disclaimer-p"}>The Portfolio feature is here for you
                                    to showcase all of your work so brands can understand your range.</p>
                            </div>
                        )}
                        {portfolioDisclaimer && !ownProfile && (
                            <div className={"portfolio-external-disclaimer-div"}>
                                <p className={"portfolio-internal-disclaimer-p"}>Reminder: Portfolio content is not
                                    royalty free. You cannot download or reuse this content in any way without owner's
                                    permission.</p>
                                <p className={"portfolio-ack-p pointer"}
                                   onClick={event => setPortfolioDisclaimer(false)}>I Understand</p>
                            </div>
                        )}
                        <Collection collections={portfolios} portfolioFlag={true} ownProfile={ownProfile}/>
                    </div>
                )}
                {activeTab === 'collections' && (
                    <Collection collections={collections} portfolioFlag={false} ownProfile={ownProfile}/>)}
                {activeTab === 'liked' && (
                    <Content profileFlag={false} content={likedContent} retrieveNextContent={loadMoreLikedImages}/>)}
            </div>
            <Modal
                isOpen={editModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"profile-modal"}
                style={style}
            >
                <div className={"edit-modal-div"}>
                    <form>
                        <h3 className={"font-bold edit-profile-h blue-color"}>It's ok to change it up bestie.</h3>
                        <p className={"edit-profile-p"}>(Edit profile)</p>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <div className={"headshot-box"}>
                                    {profileImg.profile_image &&
                                        <img className={"headshot-preview"}
                                             src={"data:" + profileImg.profile_image_type + ";base64," + profileImg.profile_image}/>
                                    }
                                    {!profileImg.profile_image && user.profile_image_exists && (
                                        <img className={"headshot-preview"}
                                             src={"https://d3p3fw3rutb1if.cloudfront.net/users/" + user.id}/>
                                    )}
                                    {!profileImg.profile_image && !user.profile_image_exists &&
                                        <img className={"headshot-preview"}
                                             src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw3NjA4Mjc3NHx8ZW58MHx8fHw%3D&w=1000&q=80"/>
                                    }
                                </div>
                                <input type="button" id="loadFileXml" className={"prof-upload-btn"}
                                       value="Upload Profile Image" onClick={() => {
                                    document.getElementById('file').click();
                                }}/>
                                <input type="file" style={{display: "none"}} id="file" name="file"
                                       onChange={(event) => {
                                           setUploadImage(event);
                                       }}/>
                            </div>
                            <div className={"col"}>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className="form-control grey-input"
                                               value={userEdit.first_name} maxLength="20" onChange={updateFirstName}
                                               placeholder="First name"/>
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control grey-input"
                                               value={userEdit.last_name} maxLength="20" onChange={updateLastName}
                                               placeholder="Last name"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className={"relative-div"}>
                                        <input type="text" value={userEdit.username} onChange={updateUsername}
                                               onBlur={triggerUsernameCheck}
                                               maxLength="25" className="form-control grey-input" id="descriptionInput"
                                               placeholder="Username"/>
                                        <span
                                            className={"input-limit-span"}>{25 - (user.username ? user.username.length : 0)}</span>
                                        {usernameError && (
                                            <p className={"username-error-p"}>{usernameError}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className={"relative-div"}>
                                        <textarea type="text" value={userEdit.description} onChange={updateDescription}
                                                  maxLength="115" rows={"4"} className="form-control grey-textarea"
                                                  id="descriptionInput" placeholder="Bio"/>
                                        <span
                                            className={"profile-textarea-limit-span"}>{115 - (userEdit.description ? userEdit.description.length : 0)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-4 position-relative">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input"
                                       value={userEdit.instagram_handle} onChange={updateInstagram}
                                       placeholder="Instagram"/>
                            </div>
                            <div className="col-4 position-relative">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input"
                                       value={userEdit.tik_tok_handle} onChange={updateTikTok} placeholder="TikTok"/>
                            </div>
                            <div className="col-4 position-relative">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input"
                                       value={userEdit.youtube_handle} onChange={updateYoutube} placeholder="Youtube"/>
                            </div>
                        </div>
                        <div className={"edit-profile-footer"}>
                            <button className={"light-grey-btn"}>Cancel</button>
                            <button type="submit" onClick={saveUser} className="btn blue-btn">Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                isOpen={followingModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"following-modal"}
                style={style}
            >
                <div>
                    <div className={"following-headers-div"}>
                        <h3 className={"font-bold pointer " + (followingTab === "following" ? "tab-selected" : "")}
                            onClick={event => setFollowingTab("following")}>Following</h3>
                        <h3 className={"font-bold pointer " + (followingTab === "followers" ? "tab-selected" : "")}
                            onClick={event => setFollowingTab("followers")}>Followers</h3>
                    </div>
                    <div className={"following-search-div"}>
                        <input className={"following-search"} placeholder={"search"} value={followingSearch}
                               onChange={searchFollowing}/>
                        <img src={searchIcon}/>
                    </div>
                    <div className={"follow-container"}>
                        {followings && followings[followingTab] && followings[followingTab].map(follow => (
                            <div className={"follow-div"}>
                                {follow.profile_image_exists && (
                                    <Link to={'/profile/' + follow.username} style={{textDecoration: 'none'}}
                                          reloadDocument>
                                        <img className="following-profile-image pointer" alt=""
                                             src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + follow.user_id}/>
                                    </Link>
                                )}
                                {!follow.profile_image_exists && (
                                    <img className="following-profile-image" alt="" src={defaultProfile}/>
                                )}
                                <div className={"follow-user-info-div"}>
                                    <Link to={'/profile/' + follow.username} style={{textDecoration: 'none'}}
                                          reloadDocument>
                                        <p className={"font-bold pointer following-username-p"}
                                           onClick={event => navigate('/profile/' + follow.username)}>{follow.first_name} {follow.last_name}</p>
                                    </Link>
                                    <p>@{follow.username}</p>
                                </div>
                                {follow.requestor_following && !follow.requestors_profile && (
                                    <button className={"blue-btn following-btn-width"}
                                            onClick={event => removeFollowing(follow.user_id)}>following</button>
                                )}
                                {!follow.requestor_following && !follow.requestors_profile && (
                                    <button className={"blue-inverse-btn following-btn-width"}
                                            onClick={event => addFollowing(follow.user_id)}>follow</button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={editContentModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"edit-content-modal"}
                style={style}
            >
                <div className={"delete-content-div"}>
                    <div className={"deleted-content-img-div"}>
                        {selectedContent.content_type === "VIDEO" && (
                            // <img className="content-image" alt="" src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + image.img_preview_id} onClick={() => openContentModal(image.id)} />
                            <video className="content-image" controls loop autoPlay muted>
                                <source
                                    src={"https://d3p3fw3rutb1if.cloudfront.net/videos/" + selectedContent.img_preview_id}
                                    type={"video/mp4"}/>
                            </video>
                        )}
                        {selectedContent.content_type !== "VIDEO" && (
                            <img alt="not fount"
                                 src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + selectedContent.img_preview_id}/>
                        )}
                    </div>
                    <div className={"delete-content-info"}>
                        <div className={"aesthetic-container"}>
                            <div>
                                <input className={"aesthetic-input "} value={aestheticSearch}
                                       onFocus={openAestheticDropdown}
                                       onBlur={hideAestheticDropdown}
                                       onChange={(e) => setAestheticSearch(e.target.value)}
                                       placeholder={(selectedAesthetics.length > 1) ? "Aesthetics are full" : "Add up to two aesthetics"}
                                       disabled={(selectedAesthetics.length > 1) ? "disabled" : ""}/>
                                <div className={showAesthetics ? "aesthetic-dropdown" : "d-none"}>
                                    {aesthetics.map((aesthetic) => (
                                        <div key={aesthetic.id} onMouseDown={() => addAesthetic(aesthetic)}>
                                            <p className={"aesthetic-dropdown-p"}>{aesthetic.aesthetic}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className={"upload-aesthetics-div"}>
                                    {(!selectedAesthetics || selectedAesthetics.length < 1) && (
                                        <div>
                                            <p className={"aesthetic-warning-p"}>Don't worry if your photo doesn't match
                                                an aesthetic,</p>
                                            <p className={"aesthetic-warning-p"}>it's still searchable</p>
                                        </div>
                                    )}
                                    {selectedAesthetics && selectedAesthetics.map((aesthetic) => (
                                        <button className={"aesthetic-button"}
                                                onClick={() => removeAesthetic(aesthetic)}>x {aesthetic.aesthetic}</button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={"label-container"}>
                            <div>
                                <input className={"label-input"}
                                       maxLength="25"
                                       onFocus={openLabelDropdown}
                                       onBlur={hideLabelDropdown}
                                       placeholder={(selectedLabels.length > 9) ? "Tags are full" : "Add up to ten tags"}
                                       disabled={(selectedLabels.length > 9) ? "disabled" : ""}
                                       onKeyDown={(event) => createLabel(event)}/>
                                <div className={(showLabels ? "aesthetic-dropdown" : "d-none")}>
                                    {labels && labels.map((label) => (
                                        <div onMouseDown={(event) => addAesthetic(event, label)}>
                                            <p>{label}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className={"upload-aesthetics-div"}>
                                    {(!selectedLabels || selectedLabels.length < 1) && (
                                        <p className={"aesthetic-warning-p"}>Hit enter to add tag</p>
                                    )}
                                    {selectedLabels && selectedLabels.map((label) => (
                                        <button className={"label-button"}
                                                onClick={() => removeLabel(label)}>x {label}</button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {!confirmContentDelete && (
                            <div className={"update-content-btns"}>
                                <button className={"blue-btn"} onClick={updateSelectedContent}>Save</button>
                                <button className={"red-btn"} onClick={event => setConfirmContentDelete(true)}>Delete
                                    Post
                                </button>
                            </div>
                        )}
                        {confirmContentDelete && (
                            <div className={"update-content-btns"}>
                                <button className={"cancel-delete-btn"}
                                        onClick={event => setConfirmContentDelete(false)}>Wait, jk
                                </button>
                                <button className={"delete-post-btn-right"} onClick={deleteSelectedContent}>Delete
                                    Post
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );

    function getDupeBizDisplayText() {
        if (creatorBizAvailable) {
            return "discoverable by brands";
        } else {
            return "not discoverable by brands";
        }
    }

    function getDupeBizProfileLinkText() {
        if (creatorBizAvailable) {
            return "Interact with brands";
        } else {
            return "Update";
        }
    }

    function CreatorBizStatus() {
        if (ownProfile && photoImages && photoImages.length >= 5 && emailVerified) {
            return (
                <div className="connect-with-brands-container" style={{display: "flex", flexDirection: "row"}}>
                    <div className={"connect-with-brands-p"}>
                        {creatorBizAvailable && <img className={"creator-biz-icon"} src={bizAvailableIcon} alt=""></img>}
                        {!creatorBizAvailable && <img className={"creator-biz-icon"} src={bizUnavailableIcon} alt=""></img>}
                        <p>{getDupeBizDisplayText()}</p>
                    </div>
                    <p className={"creator-biz-link-p"} onClick={() => navigate('/creator-signup')}>{getDupeBizProfileLinkText()}</p>
                </div>)
        }
    }

    function ProfileAesthetics() {
        if (user.aesthetics && user.aesthetics.length > 0) {
            return (
                <div className={"profile-aesthetic-div"}>
                    <h5 className={"aesthetic-header font-bold"}>aesthetic</h5>
                    {user.aesthetics && user.aesthetics.map((aesthetic) => (
                        <button className={"aesthetic-btn"}
                                onClick={event => navigate('/home?aesthetic=' + aesthetic)}>x {aesthetic}</button>
                    ))}
                </div>
            );
        } else {
            return (
                <div>
                    <h5 className={"aesthetic-header font-bold"}>aesthetic</h5>
                    {ownProfile && (
                        <p className={"upload-aesthetic-p pointer"} onClick={event => navigate('/upload')}>Upload photos
                            to generate aesthetics</p>
                    )}
                    {!ownProfile && (
                        <p className={"upload-aesthetic-p"}>Creator doesn't have an aesthetic yet</p>
                    )}
                </div>
            );
        }
    }
}


export default Profile
