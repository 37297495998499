import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet, Pressable, Image} from "react-native-web";
import '../stylesheets/CreatorBizSignUp.css';
import ProfileSettings from "./ProfileSettings";
import Campaign from "./Campaign";
import {getCreatorCampaigns} from "../services/CreatorService";
import profileSelected from "../icons/DupeIcons-21-Profile-Selected.png";
import profileUnselected from "../icons/DupeIcons-20-Profile-Unselected.png";
import campaignSelected from "../icons/DupeIcons-19-Campaign-Selected.png";
import campaignUnselected from "../icons/DupeIcons-18-Campaign-Unselected.png";
import messageSelected from "../icons/DupeIcons-23-Messaging.png"
import messageUnselected from "../icons/DupeIcons-24-LightGreyMessage.png"
import backToProfile from "../icons/DupeIcons-16-Biz-Unavailable.png";
import {useNavigate} from "react-router-dom";
import Message from "./Message";
import {getMessagingFlag} from "../services/UserService";
import SendbirdChat from "@sendbird/chat";
import {GroupChannelModule} from "@sendbird/chat/groupChannel";

const CreatorBizSignUp = () => {
    const [leftMenuSelection, setLeftMenuSelection] = useState('creatorProfile');
    const [campaignTabSelected, setCampaignTabSelected] = useState('activeCampaigns');
    const [acceptedCampaigns, setAcceptedCampaigns] = useState([]);
    const [invitedCampaigns, setInvitedCampaigns] = useState([]);
    const [publicCampaigns, setPublicCampaigns] = useState([]);
    const [messagingEnabled, setMessagingEnabled] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(false);
    const navigate = useNavigate();

    function handlePressCampaign() {

        getCreatorCampaigns()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setAcceptedCampaigns(resp.data.acceptedCampaigns)
                    setInvitedCampaigns(resp.data.invitedCampaigns)
                    setPublicCampaigns(resp.data.publicCampaigns)

                }
            })
            .catch(err => {
                console.log(err)
            });

        setLeftMenuSelection("campaigns")
    }

    function handlePressMessages() {
        setLeftMenuSelection("messages")
    }


    function InvitedCampaigns() {
        if (invitedCampaigns.length > 0) {
            return (
                invitedCampaigns.map((campaign) => {
                    return (<Campaign isModal={false} isInvite={true} campaign={campaign} refreshCampaigns={handlePressCampaign}/>)
                })
            )
        }
    }

    function PublicCampaigns() {
        if (publicCampaigns.length > 0) {
            return (
                publicCampaigns.map((campaign) => {
                    return (<Campaign isModal={false} campaign={campaign} refreshCampaigns={handlePressCampaign}/>)
                })
            )
        }
    }

    function ActiveCampaigns() {
        if (acceptedCampaigns.length > 0) {
            return (
                acceptedCampaigns.map((campaign) => {
                    return (<Campaign isModal={false} isActive={true} campaign={campaign} refreshCampaigns={handlePressCampaign}/>)
                })
            )
        }
    }

    useEffect(() => {
        getMessagingFlag()
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setMessagingEnabled(resp.data.enabled);
                    if (resp.data.enabled) {
                        const sb = SendbirdChat.init({
                            appId: '960C8598-DEDE-43E0-89FA-45442999623C',
                            modules: [
                                new GroupChannelModule(),
                            ],
                        });
                        const userString = localStorage.getItem("user");
                        sb.connect(JSON.parse(userString).id)
                            .then(connResp => {
                                sb.groupChannel.getTotalUnreadMessageCount()
                                    .then(unreadResp => {
                                        if (unreadResp > 0) {
                                            setUnreadMessages(true);
                                        }
                                    })
                            })
                    }
                }
            })
    }, [])

        return (
            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <View>
                    <Image source={backToProfile} style={styles.largeIcon} onClick={() => navigate('/profile')}/>
                </View>
                <View style={styles.background}>
                    <Text style={styles.welcomeText}>Welcome to Dupe Biz</Text>
                    <Pressable style={{flexDirection: 'row', padding: 10}}
                               onPress={() => setLeftMenuSelection("creatorProfile")}>
                        <Image style={{height: 25, width: 25}}
                               source={leftMenuSelection === 'creatorProfile' ? profileSelected : profileUnselected}/>
                        <Text
                            style={leftMenuSelection === 'creatorProfile' ? styles.sideMenuTextSelected : styles.sideMenuText}>Creator
                            Profile</Text>
                    </Pressable>
                    <Pressable style={{flexDirection: 'row', padding: 10}} onPress={handlePressCampaign}>
                        <Image style={{height: 25, width: 25}}
                               source={leftMenuSelection === 'campaigns' ? campaignSelected : campaignUnselected}/>
                        <Text
                            style={leftMenuSelection === 'campaigns' ? styles.sideMenuTextSelected : styles.sideMenuText}>Campaigns</Text>
                    </Pressable>
                    {messagingEnabled && (
                        <Pressable style={{flexDirection: 'row', padding: 10}} onPress={handlePressMessages}>
                            <View style={{position: 'relative', width: 25}}>
                                <Image style={{height: 25, width: 25}}
                                       source={leftMenuSelection === 'messages' ? messageSelected : messageUnselected}/>
                                {unreadMessages && (
                                    <View style={styles.redCircle} />
                                )}
                            </View>
                            <Text
                                style={leftMenuSelection === 'messages' ? styles.sideMenuTextSelected : styles.sideMenuText}>Messages</Text>
                        </Pressable>
                    )}
                </View>

                {leftMenuSelection === 'creatorProfile' && <View style={[styles.background, {width: '70%'}]}>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={styles.topMenuTextSelected}>Profile Settings</Text>
                    </View>
                    <View style={styles.line}/>
                    <ProfileSettings/>
                </View>}
                {leftMenuSelection === 'campaigns' && <View style={[styles.background, {width: '70%'}]}>
                    <View style={{flexDirection: 'row', width: '100%'}}>
                        <Pressable onPress={() => setCampaignTabSelected("activeCampaigns")}>
                            <Text
                                style={campaignTabSelected === 'activeCampaigns' ? styles.topMenuTextSelected : styles.topMenuText}>Active
                                Campaigns</Text>
                        </Pressable>
                        <Pressable onPress={() => setCampaignTabSelected("invites")}>
                            <Text
                                style={campaignTabSelected === 'invites' ? styles.topMenuTextSelected : styles.topMenuText}>Invites</Text>
                        </Pressable>
                        <Pressable onPress={() => setCampaignTabSelected("findCampaigns")}>
                            <Text
                                style={campaignTabSelected === 'findCampaigns' ? styles.topMenuTextSelected : styles.topMenuText}>Find
                                Campaigns</Text>
                        </Pressable>
                    </View>
                    <View style={styles.line}/>
                    {campaignTabSelected === 'findCampaigns' && <PublicCampaigns/>}
                    {campaignTabSelected === 'invites' && <InvitedCampaigns/>}
                    {campaignTabSelected === 'activeCampaigns' && <ActiveCampaigns/>}
                </View>}
                {leftMenuSelection === 'messages' && <View style={[styles.messagingBackground, {width: '70%'}]}>
                    <Message />
                </View>}
            </View>
        );
}

const styles = StyleSheet.create({
    text: {
        fontFamily: "'lato', sans-serif",
    },
    boldText: {
        fontWeight: 900,
        fontFamily: "'lato', sans-serif",
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
    },
    subtleMenuText: {
        color: 'grey',
        fontFamily: "'lato', sans-serif",
    },
    checkBoxContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        border: '1px solid #396DE0'
    },
    checkBox: {
        flexDirection: 'row',
        margin: 5
    },
    background: {
        backgroundColor: 'white',
        borderRadius: 20,
        border: '1px solid rgba(57, 109, 224, .3)',
        margin: 20,
        padding: 20,
        minHeight: 600,
    },
    messagingBackground: {
        backgroundColor: 'white',
        borderRadius: 20,
        border: '1px solid rgba(57, 109, 224, .3)',
        margin: 20,
        minWidth: 700,
    },
    line: {
        display: 'block',
        height: '1px',
        border: 0,
        margin: 10,
        padding: 0,
        backgroundColor: 'lightgrey'
    },
    topMenuTextSelected: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 18,
        padding: 10
    },
    topMenuText: {
        color: '#737373',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 18,
        padding: 10
    },
    welcomeText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 20,
        padding: 10
    },
    campaignCompanyText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 900,
        fontSize: 24,
        margin: 2
    },
    campaignNameText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        fontWeight: 500,
        margin: 2
    },
    sideMenuTextSelected: {
        color: '#396DE0',
        fontFamily: "'lato', sans-serif",
        fontWeight: 700,
        fontSize: 18,
        marginLeft: 5
    },
    sideMenuText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        marginLeft: 5
    },
    applyButton: {
        borderRadius: 20,
        backgroundColor: '#396DE0',
        minWidth: 125,
        margin: 5
    },
    applyButtonText: {
        color: 'white',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    previewButton: {
        borderRadius: 20,
        backgroundColor: '#B8CBF5',
        minWidth: 125,
        margin: 5
    },
    previewButtonText: {
        color: '#396DE0',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    modal:
        {
            overlay: {
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                backgroundColor: 'rgba(57, 109, 224, .5)'
            },
            content: {
                minWidth: 750,
                margin: '20%',
                inset: 0,
                background: 'none',
                flex: 1,
                border: 'none',
                alignSelf: 'center',
                alignContent: 'center'
            }
        },
    dude: {
        color: '#C6CA53',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 35,
        textDecorationLine: 'underline',
        padding: 10,
    },
    applyAcceptText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 50,
        padding: 10,
    },
    boldFormaText: {
        fontWeight: 900,
        fontFamily: "'forma-djr-display', sans-serif",
        padding: 10,
    },
    lato: {
        fontFamily: "'lato', sans-serif",
        padding: 10,
    },
    normalText: {
        padding: 10,
    },
    largeIcon: {
        width: 30,
        height: 30,
        marginTop: 20,
        padding: 20,
        cursor: 'pointer'
      },
    redCircle: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: 'red',
    }

});
export default CreatorBizSignUp;
