import React, {useState} from 'react'
import {useNavigate, useSearchParams, useLocation} from 'react-router-dom';
import {getProfile} from "../services/UserService";
import {signIn, signOut, fetchAuthSession, signInWithRedirect} from 'aws-amplify/auth';
import '../stylesheets/Login.css';
import openEye from "../icons/DupeIcons_Eye-Open.png";
import closedEye from "../icons/DupeIcons_Eye-Closed.png";
import { TouchableOpacity, Image, Text, StyleSheet, View, useWindowDimensions, TextInput } from 'react-native-web';
import googleIcon from "../icons/google-icon.png";
import appleIcon from "../icons/apple-icon.png";
import mailIcon from "../icons/mail-icon.png";
const Login = ({successfulLoginCallback, message, isModal=false,}) => {
    const { state } = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const searchParams = useSearchParams();
    const navigate = useNavigate();
    const [showEmailLogin, setShowEmailLogin] = useState(false);
    const { width } = useWindowDimensions();
    const [errorText, setErrorText] = useState(message ? message :state?.message);

    const createUser = () => {
        if (email && password) {
            signOut().then(() => {
                signIn({
                    password: password,
                    username: email,
                    options: {
                        authFlowType: 'USER_PASSWORD_AUTH',
                    },
                }).then(() => {
                    fetchAuthSession().then(session => {
                        getProfile()
                            .then((user) => {
                                localStorage.setItem('user', JSON.stringify(user.data))
                                localStorage.setItem('token', session.tokens.idToken.toString())
                                successfulLoginCallback();
                            })
                    })
                }).catch(err => {
                    setErrorText(err.message);
                });
            })
        }
    }

    const handleEmailLogin = () => {
        setErrorText(null);
        setShowEmailLogin(true)
    }

    const handleGoogleLogin = () => {
        setErrorText(null);
        signOut().then(() => {
            signInWithRedirect({
                provider: "Google",
                options: {
                    preferPrivateSession: true,
                },
            }).then(() => {
                fetchAuthSession().then(session => {
                    if (searchParams.get("inviteId") != null) {
                        navigate('/join/' + searchParams.get("inviteId"));
                    } else {
                        navigate('/');
                    }
                }).catch(err => {
                    console.log(err)
                    // setErrorText(err);
                })
            }).catch(err => {
                console.log(err)
                // setErrorText(err);
            })
        })
    }

    const handleAppleLogin = () => {
        signOut().then(() => {
            setErrorText(null);
            signInWithRedirect({
                provider: "Apple",
                options: {
                    preferPrivateSession: true,
                },
            }).then(() => {
                fetchAuthSession().then(session => {
                    if (searchParams.get("inviteId") != null) {
                        navigate('/join/' + searchParams.get("inviteId"));
                    } else {
                        navigate('/');
                    }
                }).catch(err => {
                    console.log(err)
                })
            }).catch(err => {
                console.log(err)
            })
        })
    
    }

    return(
        <View style={styles.container}>
            {!isModal &&width > 1000 && <View style={{flex: 1, width: "60%", height: "100%"}}>
                <Image
                    source={{uri: "https://dupe-photos.s3.amazonaws.com/Login_Return.jpg"}}
                    style={{width: "100%", height: "100%"}}
                />
            </View>}
            <View style={{alignItems: "center", width: "40%"}}>
                <Image
                    style={styles.loginLogo}
                    source={require("../icons/Dupe_Logo_Blue.png")}
                    resizeMode="contain"
                    resizeMethod="resize"
                />
                {!showEmailLogin && <View>
                    <TouchableOpacity
                        onPress={handleGoogleLogin}
                        style={styles.socialBtn}>
                        <Image source={googleIcon} style={styles.googleIcon}></Image>
                        <Text style={[styles.socialLoginText, styles.lato]}>Continue with Google</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={handleAppleLogin}
                        style={styles.socialBtn}>
                        <Image source={appleIcon} style={styles.appleIcon}></Image>
                        <Text style={[styles.socialLoginText, styles.lato]}>Continue with Apple</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={handleEmailLogin}
                        style={styles.continueWithEmailBtn}>
                        <Image source={mailIcon} style={styles.mailIcon}></Image>
                        <Text style={[styles.loginText, styles.latoBold]}>Continue with Email</Text>
                    </TouchableOpacity>
                </View>}

                {showEmailLogin && <View style={{ alignItems: 'center' }}>
                    <View style={styles.inputView}>
                        <TextInput
                            style={styles.inputText}
                            placeholder="Email"
                            placeholderTextColor="white"
                            selectionColor="white"
                            autoCapitalize="none"
                            value={email}
                            onChangeText={text => setEmail(text)}
                            onSubmitEditing={createUser}
                        />
                    </View>
                    <View style={styles.inputView}>
                        <TextInput
                            style={styles.inputText}
                            placeholder="Password"
                            placeholderTextColor="white"
                            selectionColor="#236fea"
                            autoCapitalize="none"
                            secureTextEntry={!showPassword}
                            value={password}
                            onChangeText={text => setPassword(text)}
                            onSubmitEditing={createUser}
                        />
                        <TouchableOpacity 
                            style={styles.eyeIconContainer} 
                            onPress={() => setShowPassword(!showPassword)}
                        >
                            <Image source={showPassword ? openEye : closedEye} style={styles.eyeIcon} />
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.text} onPress={() => navigate('/forgot_password')}>
                        Forgot Password?
                    </Text>
                    <TouchableOpacity
                        onPress={createUser}
                        style={styles.loginBtn}>
                        <Text style={[styles.loginText, styles.lato]}>Login</Text>
                    </TouchableOpacity>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={styles.text}>Don't have an account?</Text>
                        <Text style={[styles.text, { color: 'blue' }]} onPress={() => navigate('/signup')}> Sign up</Text>
                    </View>
                </View>}
                {errorText && <Text style={styles.errorText}>{errorText}</Text>}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        height: "100vh",
        width: "100%",
        backgroundColor: '#f8f7f4',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "row"
    },
    continueWithEmailBtn:{
        flexDirection: "row",
        width: 350,
        backgroundColor:"#236fea",
        borderRadius:25,
        height:50,
        alignItems:"center",
        justifyContent:"center",
        marginTop: 10,
        marginBottom: 10
    },  
    socialBtn:{
        flexDirection: "row",
        width: 350,
        backgroundColor: "white",
        borderRadius:25,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "black",
        height:50,
        alignItems:"center",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10
    },
    loginLogo: {
        width: 150,
        height: 100,
        objectFit: 'contain',
        imageRendering: 'auto'
    },
    loginText: {
        color: "white",
        fontSize: 20,
    },
    socialLoginText: {
        color: "black",
        fontSize: 20,
    },
    mailIcon: {
        width: 20,
        height: 20,
        marginRight: 10
    },
    appleIcon: {
        width: 20,
        height: 20,
        marginBottom: 3,
        marginRight: 10
    },
    googleIcon: {
        width: 30,
        height: 30,
        marginRight: 10
    },
    lato: {
        fontFamily: "'Lato', sans-serif"
    },
    latoBold: {
        fontFamily: "'Lato', sans-serif",
        fontWeight: 700
    },
    errorText: {
        color: 'red',
        fontFamily: "'Lato', sans-serif",
        fontSize: 16,
        width: 350,
        textAlign: 'center'
    },
    inputView: {
        position: 'relative',
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
    inputText: {
        width: 350,
        height: 50,
        flex: 1,
        color: "white",  
        fontFamily: "'Lato', sans-serif",
        border: "none",
        paddingVertical: 10, 
        paddingHorizontal: 20,
        borderRadius: 25,
        backgroundColor: "#236fea", 
        outlineWidth: 0,
        fontSize: 16,
        WebkitBoxShadow: '0 0 0px 1000px #236fea inset',
        WebkitTextFillColor: 'white',
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0px 1000px #236fea inset !important',
            WebkitTextFillColor: 'white !important',
            caretColor: 'white !important',
        },
        '&:-webkit-credentials-auto-fill-button': {
            fontFamily: "'Lato', sans-serif !important",
            fontSize: '16px !important',
        },
        '&::-webkit-credentials-auto-fill-button:hover': {
            fontFamily: "'Lato', sans-serif !important",
            fontSize: '16px !important',
        },
        '&:-internal-autofill-selected': {
            fontFamily: "'Lato', sans-serif !important",
            fontSize: '16px !important',
        },
    },
    loginBtn: {
        width: 350,
        backgroundColor: "#c6ca53",
        borderRadius: 25,
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 40,
        marginBottom: 10
    },
    text: {
        color: "black",
        fontFamily: "'Lato', sans-serif",
        fontSize: 16
    },
    eyeIconContainer: {
        position: 'absolute',
        right: 20,
        zIndex: 1,
    },
    eyeIcon: {
        width: 20, 
        height: 20,
    },
})

export default Login
