import React from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom';
import '../stylesheets/Login.css';
import Login from "./Login";
import { Flex } from "rebass";


const LoginPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const loginCallback = () => {
        const verificationCode = searchParams.get("vcode");
        if (verificationCode) {
            navigate('/profile?vcode=' + verificationCode);
        } else {
            navigate('/home');
        }
    }

    return(
        <Flex flexDirection="row" height="100vh">
            <Login successfulLoginCallback={loginCallback} />
        </Flex>

    );
}

export default LoginPage
