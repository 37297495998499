import axios from "axios";
import contentClient from "./ContentClient";

export async function uploadContent(req) {
    return await contentClient.post('/api/v1/content', req);
}

export async function createPortfolio(req) {
    return await contentClient.post('/api/v1/content/portfolio', req);
}

export async function deleteContent(contentId) {
    return await contentClient.delete('/api/v1/content/' + contentId);
}

export async function updateContent(req) {
    return await contentClient.put('/api/v1/content', req);
}

export async function toggleLike(contentId) {
    return await contentClient.post('/api/v1/content/' + contentId + "/like", {});
}

export async function getContent(userId, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content"
    if (page != null) {
        url = url + "?page=" + page;
    }
    if (userId != null) {
        return await axios.get(url, {headers: {'USER-ID': userId}});
    } else {
        return await axios.get(url);
    }
}

export async function getAllContent(page, contentType, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/all?page="
    if (page != null) {
        url = url + page;
    } else {
        url = url + "1";
    }
    if (contentType != null) {
        url = url + "&contentType=" + contentType;
    }
    return await axios.get(url, {headers: {'X-CLIENT-ID': token}});
}

export async function searchContent(req) {
    return await axios.post(process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/search", req);
}


export async function getAllAestheticContent(aestheticId, page, contentType) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/aesthetics/" + aestheticId + "/content/all?page=" + page;
    if (contentType != null) {
        url = url + "&contentType=" + contentType;
    }
    return await axios.get(url);
}

export async function getUsersContent(userId, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/users/" + userId + "/content";
    if (page) {
        url = url + "?page=" + page;
    }
    return await axios.get(url);
}

export async function getUsersVideoContent(userId, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/users/" + userId + "/content/video";
    if (page) {
        url = url + "?page=" + page;
    }
    return await axios.get(url);
}

export async function getContentSummary(contentId, userId) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/" + contentId + "/preview";
    return await axios.get(url, {headers: {'USER-ID': userId}});
}

export async function getDownloadFile(contentId, token) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/" + contentId + "/download";
    const config = { responseType: 'blob', headers: {'X-CLIENT-ID': token}};
    return await axios.get(url, config);
}

export async function getAllFollowingContent(page) {
    let url = "/api/v1/following/content/all";
    if (page != null) {
        url = url + "?page=" + page;
    }
    return await contentClient.get(url);
}

export async function getLikedContent(page) {
    let url = "/api/v1/content/liked";
    if (page != null) {
        url = url + "?page=" + page;
    }
    return await contentClient.get(url);
}

export async function getS3Content(contentId) {
    let url = "https://d3p3fw3rutb1if.cloudfront.net/photos/"+contentId;
    return await axios.get(url, {responseType: "blob"});
}

export async function getSimilarContent(contentId) {
    return await contentClient.get("/api/v1/content/" + contentId + "/similar");
}
