import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import AestheticBar from "./AestheticBar";
import {
    getAllAestheticContent, getAllContent, getAllFollowingContent  
} from "../services/ContentService";
import Content from "./Content";
import {useNavigate, useSearchParams} from "react-router-dom";
import '../stylesheets/Discovery.css';
import '../stylesheets/Discovery.mobile.css';
import {getAesthetics, searchAesthetics} from "../services/AestheticService";
import Popup from 'reactjs-popup';
import AppStoreButton from "../icons/app_store_button.png";
import GooglePlayButton from "../icons/google_play_button.png";
import {fetchAuthSession} from "aws-amplify/auth";

const Discovery = ({landingPage}) => {

    const [images, setImages] = useState([]);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [forYouImages, setForYouImages] = useState([]);
    const [followingImages, setFollowingImages] = useState([]);
    const [selectedAestheticId, setSelectedAestheticId] = useState(null);
    const [followingSelected, setFollowingSelected] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [aesthetics, setAesthetics] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const searchRef = useRef();
    const aestheticRef = useRef();
    const followingRef = useRef();
    const pageRef = useRef();
    const [followingPage, setFollowingPage] = useState(2);
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const closeModal = () => setOpen(false);

    const searchAesthetic = (aesthetic) => {
        getAllAestheticContent(aesthetic, 1, searchParams.get("contentType"))
            .then((res) => {
                searchParams.delete("aesthetic")
                searchParams.delete("search")
                searchParams.set("aesthetic", aesthetic)
                setSearchParams(searchParams);
                if (res.status == 200) {
                    setSearchError(false);
                    pageRef.current = 2;
                    setImages(res.data);
                    aestheticRef.current = aesthetic;
                    searchRef.current = null;
                }
            })
    }

    const applyFilter = (contentType) => {
        searchParams.set("contentType", contentType)
        setSearchParams(searchParams);
        if (aestheticRef.current) {
            getAllAestheticContent(aestheticRef.current, 1, contentType)
                .then((res) => {
                    if (res.status == 200) {
                        setImages(res.data);
                        pageRef.current = 1;
                    }
                })
        } else {
            if (!followingRef.current) {
                return getAllContent(1, contentType)
                    .then(resp => {
                        if (resp.status == 200 && resp.data) {
                            setImages(resp.data);
                            pageRef.current = 2;
                            setForYouImages([resp.data]);
                        }
                    })
            } else {
                getAllFollowingContent(followingPage)
                    .then(followRes => {
                        if (followRes.status == 200 && followRes.data) {
                            setAdditionalImages(followRes.data);
                            setFollowingPage(followingPage+1);
                            setFollowingImages(prevState => [...prevState, ...followRes.data]);
                        }
                    })
            }
        }
    }

    function retrieveNextContent() {
        if (aestheticRef.current) {
            getAllAestheticContent(aestheticRef.current, pageRef.current, searchParams.get("contentType"))
                .then((res) => {
                    if (res.status == 200) {
                        setAdditionalImages(res.data);
                        pageRef.current = pageRef.current+1;
                    }
                })
        } else {
            if (!followingRef.current) {
                return getAllContent(pageRef.current, searchParams.get("contentType"))
                    .then(resp => {
                        if (resp.status == 200 && resp.data) {
                            setAdditionalImages(resp.data);
                            pageRef.current = pageRef.current+1;
                            setForYouImages(prevState => [...prevState, ...resp.data]);
                        }
                    })
            } else {
                getAllFollowingContent(followingPage)
                    .then(followRes => {
                        if (followRes.status == 200 && followRes.data) {
                            setAdditionalImages(followRes.data);
                            setFollowingPage(followingPage+1);
                            setFollowingImages(prevState => [...prevState, ...followRes.data]);
                        }
                    })
            }
        }
    }

    function likeToggleFunction(imageId) {
        if (followingRef.current) {
            setFollowingImages(followingImages.map(image => {
                if (image.id === imageId) {
                    return {...image, liked: !image.liked};
                }
                return image;
            }))
        } else {
            setForYouImages(forYouImages.map(image => {
                if (image.id === imageId) {
                    return {...image, liked: !image.liked};
                }
                return image;
            }))
        }
    }

    function selectForYouTab() {
        setFollowingSelected(false);
        followingRef.current = false;
        setImages(forYouImages);
    }

    function selectFollowingTab() {
        setFollowingSelected(true);
        followingRef.current = true;
        setImages(followingImages);
    }


    useEffect(()=>{
        if (searchParams.get("content")) {

        } else if (!searchParams.get("aesthetic") && (aestheticRef.current != null || images == null || images.length < 1)) {
            pageRef.current = 2;
            searchRef.current = null;
            aestheticRef.current = null;
            fetchAuthSession().then((session) => {
                setLoggedIn(true);
                getAllContent(null, searchParams.get("contentType"), session.tokens.idToken.toString())
                .then((res) => {
                    setImages(res.data);
                    setForYouImages(res.data);
                    getAllFollowingContent(1)
                        .then(followRes => {
                            if (followRes.status == 200 && followRes.data) {
                                setFollowingImages(followRes.data);
                            }
                        })
                    
                })
            }).catch(() => {
                setLoggedIn(false);
                getAllContent(null, searchParams.get("contentType"))
                .then((res) => {
                    setImages(res.data);
                    setForYouImages(res.data);
                    
                })
            })

            getAesthetics()
                .then((res) => {
                    setAesthetics(res.data)
                })
        } else if (searchParams.get("aesthetic") && searchParams.get("aesthetic") != aestheticRef.current) {
            pageRef.current = 2;
            searchAesthetic(searchParams.get("aesthetic"));
            setSelectedAestheticId(searchParams.get("aesthetic"));
            getAesthetics()
                .then((res) => {
                    setAesthetics(res.data)
                })
        }
    },[searchParams]);


    return(
        <div className={"discovery-container"}>
            <Popup className="app-popup-container" open={open} closeOnDocumentClick={false} onClose={closeModal}>
                <div className="app-popup">
                    <div className="close" onClick={closeModal}>
                        &times;
                    </div>
                    <h1 className="h1">
                        pssst...
                    </h1>
                    <h2 className="h2">
                        our app is like,
                    </h2>
                    <h2 className="h2">
                        wayyyy better
                    </h2>
                    <p className="p">
                        Available now for iOS and Android
                    </p>

                        <a href="https://apps.apple.com/us/app/dupe-photos/id6498866447"><img className="mobile-button" src={AppStoreButton} alt=""/></a>
                        <a href="https://play.google.com/store/apps/details?id=com.dupephotos.dupe&pcampaignid=web_share"><img className="mobile-button" src={GooglePlayButton} alt=""/></a>
                </div>
            </Popup>
            <AestheticBar selectedAestheticId={selectedAestheticId} searchFunc={searchAesthetic} aesthetics={aesthetics} applyFilter={applyFilter}/>
            {!searchRef.current && !aestheticRef.current && loggedIn && (
                <div className={"following-div"}>
                    <h5 className={"following-tabs font-bold pointer " + (!followingSelected ? "selected-tab" : "")} onClick={selectForYouTab}>FOR YOU</h5>
                    <h5 className={"following-tabs font-bold pointer " + (followingSelected ? "selected-tab" : "")} onClick={selectFollowingTab}>FOLLOWING</h5>
                </div>
            )}
            {searchError && searchRef.current && (
                <div className={"search-error-div"}>
                    <h3 className={"font-bold"}>No images were found for "{searchRef.current}"</h3>
                </div>
            )}
            <div className={searchError ? "d-none" : ""}>
                <Content content={images} addCollectionEnabled={true} retrieveNextContent={retrieveNextContent} additionalContent={additionalImages} likeFunc={likeToggleFunction}/>
            </div>
            {!landingPage && (
                <p className={"mobile-upload-plus box-shadow"} onClick={event => navigate('/upload')}>+</p>
            )}
        </div>
    );
}

export default Discovery
