import React, {useState} from 'react'
import {resetPassword, confirmResetPassword} from 'aws-amplify/auth';
import {Link, useNavigate} from 'react-router-dom';
import '../stylesheets/Login.css';
import openEye from "../icons/DupeIcons_Eye-Open.png";
import closedEye from "../icons/DupeIcons_Eye-Closed.png";


const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [showError, setShowError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validationError, setValidationError] = useState(null);
    const navigate = useNavigate();

    const enterEmail = (event) => {
        event.preventDefault();
        setShowVerification(true);
        if (email) {
            resetPassword({
                username: email
            }).then((result) => {
                console.log(result);
                setShowVerification(true);
            })
            .catch(err => {
                console.log(err);
                setShowError(true);
            });
        }

    }

    const updatePassword = (event) => {
        event.preventDefault();
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (password && password !== confirmPassword) {
            setValidationError("Passwords must match");
        } else if (password && !password.match(passwordRegex)) {
            setValidationError("Password must be at least 8 characters long and contain at least one letter, one number, and one special character");
        } else if (!verificationCode) {
            setValidationError("Please enter the verification code sent to your email")
        } else {
            confirmResetPassword({
                username: email,
                newPassword: password,
                confirmationCode: verificationCode
            })
                .then((data) => navigate('/login'))
                .catch((err) => setValidationError("Error updating your password"));
        }

    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    return(
        <div>
            <div className="w-50 d-none d-lg-inline-block">
                <img className="login-img" src="https://dupe-photos.s3.amazonaws.com/welcome-back.png" alt="Welcome back"/>
            </div>
            <div className="w-50 d-inline-block" style={{ 
                height: '100vh',
                width: '100%',
                display: 'flex', 
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
            }}>
                <div className="w-75 mx-auto login-div">
                    <h3>Forget your password?</h3>
                    <p className={"d-inline-block"}>Happens to the best of us</p>
                    {showError &&
                    <p className={"text-danger"}>Username or Password is incorrect</p>
                    }
                    {!showVerification && (
                        <form className="row g-3">
                            <div className="col-12">
                                <p>Enter your email:</p>
                                <input type="text" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary login-page-btn" type="submit" onClick={enterEmail}>Next</button>
                            </div>
                            <div className="col-md-12">
                                <Link to="/login">
                                    <p>Back to Login</p>
                                </Link>
                            </div>
                        </form>
                    )}
                    {showVerification && (
                        <form className="row g-3">
                            {validationError && (
                                <div className="col-12">
                                    <p className={"validation-error"}>{validationError}</p>
                                </div>
                            )}
                            <div className="col-12">
                                <input type="text" className="form-control" id="email" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} placeholder="Verification Code"/>
                            </div>
                            <div className="col-md-12 password-div">
                                <input type={showPassword ? "text" : "password"} className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="New Password"/>
                                {showPassword &&
                                <img className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye} alt="closed eye" />
                                }
                                {!showPassword &&
                                <img className={"password-eye-img"} onClick={toggleShowPassword} src={openEye} alt="open eye" />
                                }
                            </div>
                            <div className="col-md-12 password-div">
                                <input type={showPassword ? "text" : "password"} className="form-control" id="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password"/>
                                {showPassword &&
                                <img className={"password-eye-img"} onClick={toggleShowPassword} src={closedEye} alt="closed eye" />
                                }
                                {!showPassword &&
                                <img className={"password-eye-img"} onClick={toggleShowPassword} src={openEye} alt="open eye" />
                                }
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary login-page-btn" type="submit" onClick={updatePassword}>Set Password</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>

    );
}

export default ForgotPassword
