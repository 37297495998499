import dupeClient from "./DupeClient";

export async function saveCreator(request) {
    return await dupeClient.post("/api/v1/creators", request);
}

export async function getCreatorProfile() {
    return await dupeClient.get("/api/v1/creators");
}

export async function getCreatorCampaigns() {
    return await dupeClient.get("/api/v1/creators/campaigns/overview");
}

export async function applyToCampaign(request, campaignId) {
    return await dupeClient.post("/api/v1/creators/campaigns/" + campaignId + "/apply", request);
}

export async function acceptCampaign(campaignId) {
    return await dupeClient.post("/api/v1/creators/campaigns/" + campaignId + "/accept", {});
}

export async function denyCampaign(campaignId) {
    return await dupeClient.delete("/api/v1/creators/campaigns/" + campaignId);
}

export async function getCampaignContent(campaignId) {
    return await dupeClient.get("/api/v1/creators/campaigns/" + campaignId + "/content");
}