import dupeClient from "./DupeClient";

export async function getNotifications() {
    const result = await dupeClient.get('/api/v1/notifications')
    return result;
}

export async function readNotifications() {
    const result = await dupeClient.post('/api/v1/notifications/read', {})
    return result;
}