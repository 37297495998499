import {Pressable, StyleSheet, Text, TextInput, View, Image, TouchableOpacity} from "react-native-web";
//import {Image} from "rebass";
import React, {useEffect, useState, useCallback} from "react";
import Modal from "react-modal";
import {acceptCampaign, applyToCampaign, denyCampaign, getCampaignContent} from "../services/CreatorService";
import {Link} from "react-router-dom";
import calendarIcon from "../icons/calendar.png";
import moneyIcon from "../icons/money.png";
import Upload from "./Upload";
import xIcon from "../icons/DupeIcons-16-Biz-Unavailable.png";
import blueLandscapeIcon from "../icons/blue-landscape-icon.png";
import ReactPlayer from "react-player/lazy";

const Campaign = (props) => {
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
    const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [isApplySuccessModalOpen, setIsApplySuccessModalOpen] = useState(false);
    const [isAcceptSuccessModalOpen, setIsAcceptSuccessModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isUploadedContentModalOpen, setIsUploadedContentModalOpen] = useState(false);
    const [isApplicationPromptOpen, setIsApplicationPromptOpen] = useState(props.isApplicationPromptOpen);
    const [applicationText, setApplicationText] = useState("");
    const [isModal] = useState(props.isModal);
    const [isInvite] = useState(props.isInvite);
    const [isActive] = useState(props.isActive);

    const campaign = props.campaign;
    const [campaignId, setCampaignId] = useState('');
    const [campaignDates, setCampaignDates] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [campaignImageUrl, setCampaignImageUrl] = useState('')
    const [campaignImageExists, setCampaignImageExists] = useState(false);
    const [compensation, setCompensation] = useState('');
    const [campaignPreview, setCampaignPreview] = useState('');
    const [campaignIndustry] = useState('            ');
    const [campaignBriefId, setCampaignBriefId] = useState('');
    const [campaignBriefExists, setCampaignBriefExists] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);
    const [creatorsUploads, setCreatorsUploads] = useState([]);
    const [businessName, setBusinessName] = useState('');

    useEffect(() => {
        if (!campaign) {
            return;
        }

        if (campaign.id) {
            setCampaignId(campaign.id)
        }

        if (campaign.startDate && campaign.EndDate) {
            const monthFormatter = new Intl.DateTimeFormat('default', {month: 'long', day: 'numeric'});
            const startDate = Date.parse(campaign.startDate)
            const endDate = Date.parse(campaign.EndDate)
            setCampaignDates(monthFormatter.format(startDate) + ' - ' + monthFormatter.format(endDate))
        }

        if (campaign.imageId) {
            setCampaignImageUrl("https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + campaign.id);
        }

        if (campaign.imageExists) {
            setCampaignImageExists(true);
        }

        if (campaign.name) {
            setCampaignName(campaign.name)
        }

        if (campaign.description) {
            setCampaignDescription(campaign.description)
        }

        if (campaign.preview) {
            setCampaignPreview(campaign.preview)
        }

        if (campaign.briefId) {
            setCampaignBriefId(campaign.briefId)
        }

        if (campaign.briefExists) {
            setCampaignBriefExists(true);
        }

        if (campaign.paymentCompensation && campaign.productCompensation) {
            setCompensation("Payment and Free Product")
        } else if (!campaign.paymentCompensation && campaign.productCompensation) {
            setCompensation("$0 and Free Product")
        } else if (campaign.paymentCompensation && !campaign.productCompensation) {
            setCompensation("Payment")
        } else {
            setCompensation("$0")
        }

        if (campaign.businessName) {
            setBusinessName(campaign.businessName);
        }


    }, [campaign]);

    const handlePreviewCampaignButtonPress = useCallback(() => {
        if (isInvite) {
            setIsInviteModalOpen(true);
        } else {
            setIsSummaryModalOpen(true);
        }
    }, [isInvite]);

    const handleApplyModalButtonPress = useCallback(() => {
        if (!isApplicationPromptOpen) {
            setIsApplicationPromptOpen(true);
        } else {
            applyToCampaign({creatorNotes: applicationText}, campaignId)
                .then(resp => {
                    console.log(resp);
                    props.refreshCampaigns();
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    props.hide();
                    props.onApplySuccess();
                });
        }
    }, [isApplicationPromptOpen, applicationText, campaignId, props]);

    const handleApplyButtonPress = useCallback(() => {
        if (!isApplicationPromptOpen) {
            setIsApplicationModalOpen(true);
        } else {
            applyToCampaign({applicationText: applicationText}, campaignId)
                .then(resp => {
                    console.log(resp);
                    props.refreshCampaigns();
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    props.hide();
                    props.onApplySuccess();
                });
        }
    }, [isApplicationPromptOpen, applicationText, campaignId, props]);

    const handleAcceptModalButtonPress = useCallback(() => {
        acceptCampaign(campaignId)
            .then(resp => {
                console.log(resp);
                props.refreshCampaigns();
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                props.hide();
                props.onAcceptSuccess();
            });
    }, [campaignId, props]);

    const handleDenyModalButtonPress = useCallback(() => {
        denyCampaign(campaignId)
            .then(resp => {
                console.log(resp);
                props.refreshCampaigns();
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                props.hide();
            });
    }, [campaignId, props]);

    const handleApplicationTextChange = useCallback((text) => {
        setApplicationText(text);
    }, []);

    const onUploadSuccessful = useCallback((upgradeSuccessful) => {
        setIsUploadModalOpen(false);
    }, []);

    function AcceptCampaignModal() {
        return (<View style={[styles.background, {minHeight: 100}]}>
            <Text style={styles.dude}>DUDE!</Text>
            <Text style={styles.applyAcceptText}>You've accepted this campaign!</Text>
            <Text style={styles.boldFormaText}>You can now find this campaign in your "active campaigns" tab.</Text>
            <Text style={styles.normalText}>Keep an eye on your email. The brand will reach out to you with more details
                regarding this campaign.</Text>
            <Pressable style={[styles.applyButton, {maxWidth: 150, alignSelf: 'flex-end'}]}
                       onPress={() => setIsAcceptSuccessModalOpen(false)}>
                <Text style={styles.applyButtonText}>See more campaigns</Text>
            </Pressable>
        </View>)
    }

    function ApplyCampaignModal() {
        return (<View style={[styles.background, {minHeight: 100}]}>
            <Text style={styles.dude}>DUDE!</Text>
            <Text style={styles.applyAcceptText}>You‘ve applied to this campaign!</Text>
            <Text style={styles.boldFormaText}>You’ll receive an email if the brand accepts your application.</Text>
            <Text style={styles.normalText}>Once the brand has accepted your application, the campaign will show up in
                your “active campaigns”, but keep an eye on your email to see if you’ve been accepted.</Text>
            <Pressable style={[styles.applyButton, {maxWidth: 150, alignSelf: 'flex-end'}]}
                       onPress={() => setIsApplySuccessModalOpen(false)}>
                <Text style={styles.applyButtonText}>See more campaigns</Text>
            </Pressable>
        </View>)
    }

    function openUploadContentModal(creator) {
        getCampaignContent(campaign.id)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setSelectedContent(null);
                    setIsUploadedContentModalOpen(true);
                    setCreatorsUploads(resp.data);
                }
            })
    }


    return (
        <View style={[styles.background, {minHeight: 100}]}>
            <View style={{flexDirection: 'row'}}>
                <View>
                    <Image style={{borderRadius: 5, marginRight: 10, height: 125, width: 125, resizeMode: 'cover'}}
                           source={campaignImageExists
                               ? {uri: campaignImageUrl}
                               : blueLandscapeIcon
                           }
                           defaultSource={blueLandscapeIcon}
                           resizeMode="cover">
                    </Image>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', flex: 1}}>
                    <View>
                        <View style={{justifyContent: 'space-between', flex: 1}}>
                            <View>
                                <Text style={styles.campaignCompanyText}>{businessName}</Text>
                                <Text style={styles.campaignNameText}>{campaignName}</Text>
                            </View>
                            <View>
                                <View style={{flexDirection: 'row', margin: 2}}>
                                    <Image style={{height: 15, width: 15, marginRight: 5}} source={moneyIcon}/>
                                    <Text style={[styles.subtleText, {fontSize: 12}]}>{compensation}</Text>
                                </View>
                                <View style={{flexDirection: 'row', margin: 2}}>
                                    <Image style={{height: 15, width: 15, marginRight: 5}} source={calendarIcon}/>
                                    <Text style={[styles.subtleText, {fontSize: 12}]}>{campaignDates}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    {isActive && (
                        <Pressable onPress={openUploadContentModal} style={{alignItems: 'center', justifyContent: 'center'}}>
                            <Text style={styles.lightBlueBoldText}>Uploaded Content</Text>
                            <Text style={styles.lightBlueText}>{campaign.photoUploadCount} Photos</Text>
                            <Text style={styles.lightBlueText}>{campaign.videoUploadCount} Videos</Text>
                        </Pressable>
                    )}
                    {!isModal && <View>
                        <Text style={[styles.subtleText, {
                            alignSelf: 'flex-end',
                            margin: 5,
                            fontSize: 11
                        }]}>{campaignIndustry}</Text>
                        <Pressable style={styles.previewButton} onPress={handlePreviewCampaignButtonPress}>
                            <Text style={styles.previewButtonText}>Preview Campaign</Text>
                        </Pressable>
                        {!isInvite && campaign.applied && !isActive &&
                            <Pressable style={styles.appliedButton} disabled={true}>
                                <Text style={styles.applyButtonText}>Applied</Text>
                            </Pressable>
                        }
                        {!isInvite && !campaign.applied && !isActive &&
                            <Pressable style={styles.applyButton} onPress={handleApplyButtonPress}>
                                <Text style={styles.applyButtonText}>Apply</Text>
                            </Pressable>
                        }
                        {isActive &&
                            <Pressable style={styles.applyButton} onPress={() => setIsUploadModalOpen(true)}>
                                <Text style={styles.applyButtonText}>Upload Content</Text>
                            </Pressable>
                        }
                        {isInvite &&
                            <View style={{flexDirection: 'row'}}>
                                <Pressable style={[styles.applyButton, {minWidth: 75}]}
                                           onPress={handleAcceptModalButtonPress}>
                                    <Text style={styles.applyButtonText}>Accept</Text>
                                </Pressable>
                                <Pressable onPress={handleDenyModalButtonPress}
                                           style={[styles.applyButton, {backgroundColor: '#A6A6A6', minWidth: 75}]}>
                                    <Text style={styles.applyButtonText}>Deny</Text>
                                </Pressable>
                            </View>
                        }
                    </View>}
                </View>
            </View>

            <View>
                {isModal && !isApplicationPromptOpen &&
                    <View style={styles.campaignDescView}>
                        <View style={styles.background}>
                            {campaign.message && (
                                <View style={{marginBottom: 10}}>
                                    <Text style={styles.campaignMessageHeaderText}>Message From the Brand:</Text>
                                    <Text style={styles.sideMenuText}>{campaign.message}</Text>
                                </View>
                            )}
                            <Text style={styles.campaignCompanyText}>About this Campaign</Text>
                            <Text style={styles.sideMenuText}>{campaignDescription}</Text>
                        </View>
                    </View>
                }

                {isModal && isApplicationPromptOpen &&
                    <View style={{paddingTop: 10}}>
                        <Text style={styles.boldText}>Give a few sentences why you would be a good fit for this
                            campaign</Text>
                        <View style={[styles.checkBoxContainer, {flexDirection: 'column'}]}>
                            <TextInput
                                multiline
                                rows={6}
                                maxLength={500}
                                onChangeText={handleApplicationTextChange}
                                value={applicationText}
                                style={{padding: 10, display: 'flex', color: 'black', fontFamily: "'lato', sans-serif"}}
                            />
                            <Text style={[styles.subtleText, {alignSelf: 'flex-end'}]}>
                                ({applicationText.length}/500)
                            </Text>
                        </View>
                    </View>

                }

                {isModal &&
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingTop: 10,
                        alignItems: 'baseline'
                    }}>
                        <View>
                        {campaignBriefExists && campaignBriefId !== null && campaignBriefId !== '' && <Pressable style={{minWidth: 125}}>
                                <Link
                                    to={"https://d3p3fw3rutb1if.cloudfront.net/business/attachments/" + campaignId}
                                    target="_blank">
                                    <Text style={[styles.previewButtonText, {
                                        textDecorationLine: 'underline',
                                        padding: 0
                                    }]}>View Campaign Brief
                                    </Text>
                                </Link>
                            </Pressable>}
                        </View>
                        {!isInvite &&
                            <View>
                                <Pressable style={[styles.applyButton, {minWidth: 125, margin: 0}]}
                                           onPress={handleApplyModalButtonPress}>
                                    <Text style={styles.applyButtonText}>
                                        Apply
                                    </Text>
                                </Pressable>
                            </View>
                        }
                        {isInvite &&
                            <View style={{flexDirection: 'row'}}>
                                <Pressable style={[styles.applyButton, {minWidth: 75}]}
                                           onPress={handleAcceptModalButtonPress}>
                                    <Text style={styles.applyButtonText}>Accept</Text>
                                </Pressable>
                                <Pressable style={[styles.applyButton, {backgroundColor: '#A6A6A6', minWidth: 75}]}
                                           onPress={handleDenyModalButtonPress}>
                                    <Text style={styles.applyButtonText}>Deny</Text>
                                </Pressable>
                            </View>
                        }
                    </View>
                }
            </View>

            <Modal
                style={styles.modal}
                isOpen={isSummaryModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsSummaryModalOpen(false)}>
                <Campaign
                    isModal={true}
                    isApplicationPromptOpen={false}
                    hide={() => setIsSummaryModalOpen(false)}
                    onApplySuccess={() => {
                        setIsApplySuccessModalOpen(true);
                        handleApplyModalButtonPress();
                    }}
                    campaign={props.campaign}
                />
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isApplicationModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsApplicationModalOpen(false)}>
                <Campaign
                    isModal={true}
                    isApplicationPromptOpen={true}
                    hide={() => setIsApplicationModalOpen(false)}
                    onApplySuccess={() => {
                        setIsApplySuccessModalOpen(true);
                        handleApplyModalButtonPress();
                    }}
                    campaign={props.campaign}
                />
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isInviteModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsInviteModalOpen(false)}>
                <Campaign
                    isModal={true}
                    isApplicationPromptOpen={false}
                    isInvite={true}
                    hide={() => setIsInviteModalOpen(false)}
                    onAcceptSuccess={() => {
                        setIsAcceptSuccessModalOpen(true);
                        handleAcceptModalButtonPress();
                    }}
                    campaign={props.campaign}
                />
            </Modal>

            <Modal
                style={styles.modal}
                isOpen={isApplySuccessModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsApplySuccessModalOpen(false)}>
                <ApplyCampaignModal/>
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isAcceptSuccessModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsAcceptSuccessModalOpen(false)}>
                <AcceptCampaignModal/>
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isUploadModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsUploadModalOpen(false)}>
                <View style={[styles.background, {minHeight: 100}]}>
                    <Upload businessFlag={true} onUploadFunc={onUploadSuccessful} campaignId={campaignId}/>
                </View>
            </Modal>
            <Modal
                isOpen={isUploadedContentModalOpen}
                onRequestClose={() => setIsUploadedContentModalOpen(false)}
                style={styles.modal}
            >
                <View style={styles.uploadedContentView}>
                    <View>
                        {(!creatorsUploads.photos || creatorsUploads.photos.length < 1) && (!creatorsUploads.videos || creatorsUploads.videos.length < 1) && (
                            <View style={{justifyContent: 'center', alignItems: 'center', height: 200}}>
                                <Text style={styles.campaignCompanyText}>No Content Uploaded Yet</Text>
                            </View>
                        )}
                        {!selectedContent && ((creatorsUploads.photos && creatorsUploads.photos.length > 0)  || (creatorsUploads.videos && creatorsUploads.videos.length > 0)) && (
                            <View>
                                <View>
                                    <Text style={styles.blueText}>Photos</Text>
                                    <View style={{flexDirection: 'row', overflow: 'scroll', flex: 1}}>
                                        {creatorsUploads.photos && creatorsUploads.photos.map(photo => (
                                            <Pressable onPress={() => setSelectedContent(photo)}>
                                                <img className={"upload-preview-img"}
                                                     src={"https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + photo.img_preview_id} alt={""} />
                                            </Pressable>
                                        ))}
                                    </View>
                                </View>
                                <View>
                                    <Text style={styles.blueText}>Videos</Text>
                                    <View style={{flexDirection: 'row', overflow: 'scroll', flex: 1}}>
                                        {creatorsUploads.videos && creatorsUploads.videos.map(video => (
                                            <Pressable onPress={() => setSelectedContent(video)}>
                                                <ReactPlayer width={"100%"} height={200} style={{ borderRadius: "50px", marginRight: 10 }} url={"https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + video.img_preview_id} muted={true} playing={true} loop={true} />
                                            </Pressable>
                                        ))}
                                    </View>
                                </View>
                            </View>
                        )}
                        {selectedContent && (
                            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                <TouchableOpacity onPress={() => setSelectedContent(null)}>
                                    <Image source={xIcon} style={{width: 20, height: 20}} />
                                </TouchableOpacity>
                                {selectedContent.content_type === "PHOTO" && (
                                    <img className={"big-upload-preview-img"}
                                         src={"https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + selectedContent.img_preview_id} alt={""} />
                                )}
                                {selectedContent.content_type === "VIDEO" && (
                                    <ReactPlayer width={"100%"} height={400} style={{ borderRadius: "50px", marginLeft: 10, }} url={"https://d3p3fw3rutb1if.cloudfront.net/business/uploads/" + selectedContent.img_id} muted={false} playing={true} loop={true} />
                                )}
                            </View>
                        )}
                    </View>
                </View>
            </Modal>
        </View>
    )
}

const styles = StyleSheet.create({
    background: {
        backgroundColor: 'white',
        borderRadius: 20,
        border: '1px solid rgba(57, 109, 224, .3)',
        margin: 20,
        padding: 20,
        minHeight: 350,
    },
    customMessageBackground: {
        backgroundColor: 'white',
        borderRadius: 20,
        border: '1px solid rgba(57, 109, 224, .3)',
        margin: 20,
        padding: 20,
    },
    applyButton: {
        borderRadius: 20,
        backgroundColor: '#396DE0',
        minWidth: 125,
        margin: 5
    },
    applyButtonText: {
        color: 'white',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    appliedButton: {
        borderRadius: 20,
        backgroundColor: '#A6A6A6',
        minWidth: 125,
        margin: 5
    },
    sideMenuText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        padding: 10
    },
    campaignCompanyText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 900,
        fontSize: 24,
        margin: 2
    },
    campaignMessageHeaderText: {
        color: 'black',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        fontWeight: 500,
        margin: 2
    },
    campaignNameText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        fontWeight: 500,
        margin: 2
    },
    previewButton: {
        borderRadius: 20,
        backgroundColor: '#B8CBF5',
        minWidth: 125,
        margin: 5
    },
    previewButtonText: {
        color: '#396DE0',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
    },
    dude: {
        color: '#C6CA53',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 35,
        textDecorationLine: 'underline',
        padding: 10,
    },
    applyAcceptText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 50,
        padding: 10,
    },
    boldFormaText: {
        fontWeight: 900,
        fontFamily: "'forma-djr-display', sans-serif",
        padding: 10,
    },
    lato: {
        fontFamily: "'lato', sans-serif",
        padding: 10,
    },
    normalText: {
        padding: 10,
    },
    boldText: {
        fontWeight: 900,
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        marginBottom: 5,
    },
    checkBoxContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        border: '1px solid #396DE0'
    },
    modal:
        {
            overlay: {
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                backgroundColor: 'rgba(57, 109, 224, .5)'
            },
            content: {
                minWidth: 750,
                //margin: '20%',
                width: '70%',
                margin: 'auto',
                inset: 0,
                background: 'none',
                flex: 1,
                border: 'none',
                alignSelf: 'center',
                alignContent: 'center'
            }
        },
    campaignDescView: {
        maxHeight: '60vh',
        overflow: 'scroll',
    },
    lightBlueBoldText: {
        color: '#b8cbf5',
        fontWeight: 'bold',
    },
    lightBlueText: {
        color: '#b8cbf5',
    },
    blueText: {
        color: '#396de0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 20,
        fontWeight: 500,
        marginTop: 5,
    },
    uploadedContentView: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        width: '100%',
    },
})

export default Campaign