import React, {useEffect, useRef, useState, Fragment} from 'react'
import "../stylesheets/LandingPage.css";
import "../stylesheets/LandingPage.mobile.css";
import {getHomepageImages} from "../services/ImageService";
import {Link, Outlet, useNavigate} from "react-router-dom";
import BlueLogo from "../icons/Dupe_Logo_Blue.png";
import WhiteSearchIcon from "../icons/White-Search-Icon.png";
import DownArrow from "../icons/White-Down-Arrow.png";
import HamburgerIcon from "../icons/hamburger-icon.png";
import NavBar from "./NavBar";
import Discovery from "./Discovery";


const LandingPage = () => {

    const [homepageImages, setHomepageImages] = useState({});
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const ref = useRef(null);
    const scrollRef = useRef(null)
    const discoveryRef = useRef();
    const handleSearch = (enteredValue) => {
        discoveryRef.current.searchContent(enteredValue);
    }


    function openSidebar() {
        setSidebarOpen(true);
    }

    function closeSidebar() {
        setSidebarOpen(false);
    }

    function setImages(data) {
        let images = {};
        data.forEach((image, index) => {
            images[index] = image;
        });
        setHomepageImages(images);
    }

    const search = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            navigate('/results?search=' + e.target.value);
        }
    }

    const scrollDown = (e) => {
        scrollRef.current.scrollIntoView()
    }

    useEffect(() => {
        const token = localStorage.getItem("token")
        if (token) {
            navigate('/home');
        } else {
            getHomepageImages()
                .then(resp => {
                    if (resp.status == 200) {
                        setImages(resp.data);
                    } else {
                        navigate('/home');
                    }
                })
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                closeSidebar();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return(
        <div className="scroll-snap-parent">
            <div className={"scroll-child"}>
                <LandingNavBar />
                <div className={"homepage-div"}>
                    <div className={"homepage-row-div"}>
                        <div className={"homepage-img-div"}>
                            {homepageImages[0] && (
                                <img src={"data:" + homepageImages[0].image_type + ";base64," + homepageImages[0].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[1] && (
                                <img src={"data:" + homepageImages[1].image_type + ";base64," + homepageImages[1].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[2] && (
                                <img src={"data:" + homepageImages[2].image_type + ";base64," + homepageImages[2].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[3] && (
                                <img src={"data:" + homepageImages[3].image_type + ";base64," + homepageImages[3].img} />
                            )}
                        </div>
                    </div>
                    <div className={"homepage-row-div"}>
                        <div className={"homepage-img-div"}>
                            {homepageImages[4] && (
                                <img src={"data:" + homepageImages[4].image_type + ";base64," + homepageImages[4].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[5] && (
                                <img src={"data:" + homepageImages[5].image_type + ";base64," + homepageImages[5].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[6] && (
                                <img src={"data:" + homepageImages[6].image_type + ";base64," + homepageImages[6].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[7] && (
                                <img src={"data:" + homepageImages[7].image_type + ";base64," + homepageImages[7].img} />
                            )}
                        </div>
                        <div>
                            <input className={"homepage-search-input font-bold"} placeholder={"what's the vibe?"} onKeyUp={search}/>
                            <img className={"landing-search-img"} src={WhiteSearchIcon} />
                        </div>
                    </div>
                    <div className={"homepage-row-div"}>
                        <div className={"homepage-img-div"}>
                            {homepageImages[8] && (
                                <img src={"data:" + homepageImages[8].image_type + ";base64," + homepageImages[8].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[9] && (
                                <img src={"data:" + homepageImages[9].image_type + ";base64," + homepageImages[9].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[10] && (
                                <img src={"data:" + homepageImages[10].image_type + ";base64," + homepageImages[10].img} />
                            )}
                        </div>
                        <div className={"homepage-img-div"}>
                            {homepageImages[11] && (
                                <img src={"data:" + homepageImages[11].image_type + ";base64," + homepageImages[11].img} />
                            )}
                        </div>
                        <div>
                            <img className={"landing-down-img pointer"} src={DownArrow} onClick={scrollDown}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"scroll-child"} ref={scrollRef}>
                <div className={"window scroll-div"}>
                    <NavBar searchFunc={handleSearch} />
                    <Discovery landingPage={true} />
                </div>
            </div>
        </div>
    );

    function LandingNavBar() {
        return (
            <Fragment>
                <nav className="navbar navbar-light dupe-navbar">
                    <div className="col-md-4 landing-login-div">
                        <Link to="/login">
                            <button className="login-btn" type="submit">login</button>
                        </Link>
                        <Link to="/signup">
                            <button className="signup-btn" type="submit">sign up</button>
                        </Link>
                    </div>
                    <div className="col-md-4 logo-center-div">
                        <Link to="/home">
                            <img className={"blue-logo-img"} src={BlueLogo} />
                            <p>&#8212; relevant royalty free imagery</p>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <div className={"profile"}>
                            <img className="hamburger pointer"
                                 src={HamburgerIcon}
                                 alt="user pic" onClick={openSidebar}
                            />
                        </div>
                    </div>
                </nav>
                <Outlet/>
                <div className={sidebarOpen?"sidenav":"sidenavClosed"} ref={ref}>
                    <h5 className={"side-x pointer font-bold"} onClick={closeSidebar}>X</h5>
                    <div className={"menu-options"}>
                        <Link to="/about-us" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>WTF IS DUPE</h3>
                        </Link>
                        <Link to="/login" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>LOG IN</h3>
                        </Link>
                        <Link to="/signup" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>SIGN UP</h3>
                        </Link>
                        <Link to="/license-and-terms" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>TERMS</h3>
                        </Link>
                        <a href={"https://blog.dupephotos.com/contact"} style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>FEEDBACK</h3>
                        </a>
                        <Link to="https://www.blog.dupephotos.com/style-guide" style={{ textDecoration: 'none' }}>
                            <h3 className={"font-bold"}>STYLE GUIDE</h3>
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default LandingPage
