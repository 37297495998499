import dupeClient from "./DupeClient";
import axios from "axios";
export async function uploadImage(formData) {
    const result = await dupeClient.post('/api/v1/images', formData, { headers: {'Content-Type': 'multipart/form-data'}})
    return result;
}

export async function uploadPrivateImage(formData) {
    const result = await dupeClient.post('/api/v1/images/private', formData, { headers: {'Content-Type': 'multipart/form-data'}})
    return result;
}

export async function getHomepageImages() {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/homepage/images')
    return result;
}

export async function uploadBusinessImage(formData, campaignId) {
    const result = await dupeClient.post('/api/v1/creators/campaigns/' + campaignId + '/content', formData, { headers: {'Content-Type': 'multipart/form-data'}})
    return result;
}